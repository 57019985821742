import {getAdDimensions} from 'app/components/ads/useAdDimensions';
import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import {useSelector} from 'react-redux';
import {selectDistrict} from 'app/hooks/reduxCreateSelectorHooks';
import {useEffect, useState} from 'react';

const AD_SLOT_HEIGHT = 320;

const useAdUnitCode = adUnit => {
  const selectedDistrict = useSelector(selectDistrict);
  const [region, setRegion] = useState(selectedDistrict?.region?.slug || 'global');

  useEffect(() => {
    if (selectedDistrict?.region) {
      setRegion(selectedDistrict?.region?.slug);
    }
  }, [selectedDistrict]);

  const networkCodeQM = '27763518,1037284';
  return `/${networkCodeQM}/FuPa_GmbH/fupa.net/${region}/${adUnit}`;
};

const getAdContainerHeight = limitedDim => {
  const mobileSizes = getAdDimensions(false);
  const desktopSizes = getAdDimensions(true, limitedDim);
  const generateMaxHeight = dimensions => {
    const heights = dimensions.filter(value => Array.isArray(value)).map(dim => dim[1]);
    return Math.max(...heights);
  };

  // [{'Content_1': {...}}, {'Native_1': {...}}, ...]
  const generatedMaxValues = Object.keys(mobileSizes).map(adUnit => {
    return {
      [adUnit]: {
        maxMobileHeight: mobileSizes[adUnit] ? generateMaxHeight(mobileSizes[adUnit]) : 0,
        maxDesktopHeight: desktopSizes[adUnit] ? generateMaxHeight(desktopSizes[adUnit]) : 0,
      },
    };
  });

  //{'Content_1': {...}, 'Native_1: {...}, ...},
  return generatedMaxValues.reduce(function (result, item) {
    const key = Object.keys(item)[0];
    result[key] = item[key];
    return result;
  }, {});
};

const isTemplateBlacklistedForAdRefresh = pathname => {
  const templateBlacklist = ['region_competitions', 'select_index', 'tv_detail', 'league_standing'];
  const {template} = generateUniqueTemplateKey(pathname);

  return {blacklisted: templateBlacklist.includes(template), template};
};

const destroySoDSkin = () => {
  // Call unload func of SoD directly to avoid race conditions between old and new campaign
  // Reset also all namespaces set by sod
  // Should fix flashing of new creatives over whole page
  try {
    window?.dspxPageSkin?.unload();
  } catch (e) {}
};

export {AD_SLOT_HEIGHT, getAdContainerHeight, isTemplateBlacklistedForAdRefresh, destroySoDSkin, useAdUnitCode};
