import District from 'app/models/District';

export const NAVBAR_HOME = 0;
export const NAVBAR_MATCHES = 1;
export const NAVBAR_COMPETITIONS = 2;
export const NAVBAR_SEARCH = 3;
const NO_ACTIVE_ITEM = -1;
const sidebarRegex = /\/(about|settings|devtools|following)/;

const initialData = {
  activeTab: NAVBAR_HOME,
  defaultUrl: {
    [NAVBAR_HOME]: '/select',
    [NAVBAR_MATCHES]: '/select',
    [NAVBAR_COMPETITIONS]: '/competitions',
    [NAVBAR_SEARCH]: '/search',
  },
  historyMap: {
    undefined: 0,
  },
};

const navBar = (state = initialData, action) => {
  switch (action.type) {
    case 'NAVBAR_LINK_CHANGE':
      const district = new District(action.data);
      return {
        ...state,
        defaultUrl: {
          0: district.getRootUrl(),
          1: district.getSubUrl('matches'),
          2: district.getSubUrl('competitions'),
          3: '/search',
        },
      };
    case 'NAVBAR_CHANGE':
      return {...state, activeTab: action.id};
    case 'UPDATE_HISTORY_MAP':
      return {...state, historyMap: {...state.historyMap, [action.data.key]: action.data.tabIndex}};
    default:
      return state;
  }
};

const createInitialNavBarData = pathname => {
  const copyInitialState = JSON.parse(JSON.stringify(initialData));
  let state = {...copyInitialState};
  let activeTab = state.activeTab;
  if (pathname.match(`^(\/region\/[a-zA-Z0-9-]+|\/my)\/matches+.*$`)) {
    activeTab = NAVBAR_MATCHES;
  } else if (pathname.match(`^(\/region\/[a-zA-Z0-9-]+|\/my)\/competitions+.*$`)) {
    activeTab = NAVBAR_COMPETITIONS;
  } else if (pathname.match(`/search`)) {
    activeTab = NAVBAR_SEARCH;
  } else if (sidebarRegex.test(pathname)) {
    activeTab = NO_ACTIVE_ITEM;
  }
  state = {...state, activeTab: activeTab, historyMap: {undefined: activeTab}};
  return state;
};

export {navBar, createInitialNavBarData};
