import {useEffect} from 'react';
import {callWebInterstital, selectAdsReady} from 'app/components/ads/AdCore';
import {useAdUnitCode} from 'app/components/ads/adHelpers';
import {useWebInterstitialFlag} from 'app/hooks/useExperimental';
import {useIsDesktop} from 'app/components/ads/useIsDesktop';
import {useSelector} from 'react-redux';
import {WEB_INTERSTITIAL_SLOT_ID} from 'app/components/ads/ad.constants';

const useWebInterstitial = () => {
  const adsReady = useSelector(selectAdsReady);
  const adUnitCode = useAdUnitCode(WEB_INTERSTITIAL_SLOT_ID);
  const webInterstitialFlag = useWebInterstitialFlag();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (adsReady && !isDesktop && webInterstitialFlag) {
      window.googletag.cmd.push(() => {
        const adSlotsOnPage = window.googletag.pubads().getSlots();
        const interstitialSlot = adSlotsOnPage.find(slot => slot.getAdUnitPath() === adUnitCode);
        if (!interstitialSlot) {
          callWebInterstital(adUnitCode);
        }
      });
    }
  }, [adsReady, isDesktop, webInterstitialFlag]);
};

export default useWebInterstitial;
