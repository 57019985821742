import {useEffect, useState} from 'react';
import {checkDesktopLayout} from 'app/styles/media';

const useIsDesktop = adUnit => {
  const [isDesktop, setIsDesktop] = useState(typeof window === 'undefined' ? false : checkDesktopLayout(adUnit));
  const updateTemplate = () => {
    setIsDesktop(checkDesktopLayout(adUnit));
  };

  useEffect(() => {
    updateTemplate();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateTemplate);
    window.addEventListener('orientationChange', updateTemplate);
    return () => {
      window.removeEventListener('resize', updateTemplate);
      window.removeEventListener('orientationChange', updateTemplate);
    };
  });
  return isDesktop;
};

export {useIsDesktop};
