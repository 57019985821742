import axios from 'axios';
import {ssrRequestContext} from 'app/ssrContext';
import axiosRetry from 'axios-retry';
import InvalidArgumentException from 'app/exceptions/InvalidArgumentException';
import * as Sentry from '@sentry/react';

export const CLIENT_API_RESPONSE_TIMEOUT = 60000;
export const SSR_API_RESPONSE_TIMEOUT = 5000;

const trackingBaseURL = process.env.TRACKING_URL;

/**
 * @returns boolean `true` if the app runs within the Lambda environment
 */
const isServerSideRendering = () => {
  return typeof window === 'undefined';
};

const createApi = (baseURL = process.env.API_URL + process.env.API_DEFAULT_VERSION) => {
  let http_sAgent = {};
  if (isServerSideRendering()) {
    if (ssrRequestContext.httpsAgent) {
      http_sAgent = ssrRequestContext.httpsAgent;
    } else {
      const https = require('https');
      http_sAgent = new https.Agent({
        keepAlive: true,
        keepAliveMsecs: 5000,
        maxSockets: 5,
        maxTotalSockets: 10,
        maxFreeSockets: 5,
        timeout: 15000,
      });
    }
  }
  return axios.create({
    timeout: isServerSideRendering() ? SSR_API_RESPONSE_TIMEOUT : CLIENT_API_RESPONSE_TIMEOUT,
    baseURL,
    ...http_sAgent,
  });
};

const api = createApi();
const authorizedAPI = createApi();
axiosRetry(authorizedAPI, {retries: 0});

const defaultRequestFullFilledHandler = config => {
  if (isServerSideRendering()) {
    // success callback
    if (ssrRequestContext?.clientIp) {
      config.headers[process.env.SSR_CLIENT_IP_HEADER] = ssrRequestContext.clientIp;
    }
    // Add origin-header for API-Requests on the server, to optimize response-cache hit-rate
    config.headers['Origin'] = process.env.SSR_ORIGIN_URL;
    // Important: explicitly enable compression! Default headers are only added in browser environment!
    // TODO: encoding/decoding seems to have a negative impact!
    //config.headers['Accept-Encoding'] = 'gzip';
  }
  if (process.env.ENVIRONMENT === 'local') {
    // config.headers['XDEBUG_SESSION'] = 'PHPSTORM';
  }

  return config;
};

const defaultRequestErrorHandler = error => {
  return Promise.reject(error);
};

// intercept every API request to add
// - debug header on local invocation
// - logging on SSR
// - original client IP on SSR
// - Bearer AuthHeader with valid token for auth protected urls
api.interceptors.request.use(
  async config => defaultRequestFullFilledHandler(config),
  // error callback
  error => defaultRequestErrorHandler(error)
);

authorizedAPI.interceptors.request.use(
  async config => {
    const newConfig = defaultRequestFullFilledHandler(config);
    // check auth protected api endpoints for valid jwt to avoid 401 errors for invalid tokens not yet refreshed
    const {tokenValidationForXHR} = await import('app/services/firebase');
    const token = await tokenValidationForXHR();
    if (token) {
      newConfig.headers['Authorization'] = `Bearer ${token}`;
    }

    return newConfig;
  },
  // error callback
  error => defaultRequestErrorHandler(error)
);

let responseInterceptorsInitialized = false; // DV-7124 prevent registering more interceptors on each SSR

const formatResponseError = error => {
  const requestConfig = error?.config
    ? {
        request: {
          url: error?.config?.url,
          method: error?.config?.method,
          headers: error?.config?.headers,
          baseURL: error?.config?.baseURL,
          data: JSON.stringify(error?.config?.data),
        },
      }
    : {};

  const response = error?.response
    ? {
        response: {
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          headers: error?.response?.headers,
          data: JSON.stringify(error?.response?.data),
        },
      }
    : {};

  const formattedError = {...requestConfig, ...response};

  return Object.keys(formattedError).length ? formattedError : error;
};

const defaultResponseFullFilledHandler = response => {
  Sentry.addBreadcrumb({
    category: 'api response',
    level: 'info',
    data: {
      requestUrl: response?.config?.url,
      status: response?.status,
      responseData: response?.data,
    },
  });
  return response;
};

const initHTTPResponseInterceptor = () => {
  if (responseInterceptorsInitialized) {
    return;
  }

  responseInterceptorsInitialized = true;

  const defaultResponseErrorHandler = error => {
    // error callback
    if (isServerSideRendering()) {
      console.log('response error: ', formatResponseError(error));
    }
    return Promise.reject(error);
  };

  // intercept every API response to add
  // - logging on SSR
  // - custom API error handler
  api.interceptors.response.use(defaultResponseFullFilledHandler, error => defaultResponseErrorHandler(error));
  authorizedAPI.interceptors.response.use(defaultResponseFullFilledHandler, error =>
    defaultResponseErrorHandler(error)
  );
};

const getHTTPDebugParams = () => {
  return api.defaults.params;
};

const setHTTPDebugParams = debugParams => {
  api.defaults.params = debugParams;
  authorizedAPI.defaults.params = debugParams;
};

const usesBasicAuth = process.env.ALLOW_BASIC_AUTH ? process.env.API_AUTH : null;

const setAuthorizationHeader = jwt => {
  if (usesBasicAuth) {
    // prevent rate limits for cypress tests
    api.defaults.headers.common['Authorization'] = usesBasicAuth;
  }
  authorizedAPI.defaults.headers.common['Authorization'] = jwt ? `Bearer ${jwt}` : usesBasicAuth;
};

setAuthorizationHeader();

// SEARCH ENDPOINTS
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
let searchRequest;

const getSearch = (query, types, region) => {
  let request = `${process.env.SEARCH_URL}/query?q=${encodeURIComponent(query)}&type=${types.join(',')}`;
  request += region ? `&prefer_region=${region}` : '';
  if (searchRequest) {
    searchRequest.cancel('Only one request allowed at a time.');
  }
  searchRequest = axios.CancelToken.source();
  return api.get(request, {cancelToken: searchRequest.token}).catch(function (thrown) {
    if (axios.isCancel(thrown)) {
      console.log('Request canceled:', thrown.message);
    }
  });
};

const cancelPendingRequest = () => {
  source.cancel('Page was left.');
};

// PAGINATION ENDPOINT
const fetchNextApiCall = request => {
  if (request) {
    return api.get(request);
  }
};

// SHARED ENDPOINTS
const getTeam = teamSlug => {
  return api.get(`/teams/${teamSlug}`);
};

const getCompetition = (competitionSlug, seasonSlug = 'current') => {
  const request = `/competitions/${competitionSlug}/seasons/${seasonSlug}`;
  return api.get(request);
};

const getCompetitionHistory = competitionSlug => {
  return api.get(`/competitions/${competitionSlug}/history`);
};

const getCompetitionStats = (competitionSlug, seasonSlug = 'current') => {
  return api.get(`/competitions/${competitionSlug}/seasons/${seasonSlug}/rankings`);
};

const getCompetitionScorers = (competitionSlug, seasonSlug = 'current') => {
  return api.get(`/competitions/${competitionSlug}/seasons/${seasonSlug}/scorers?limit=25`);
};

const permissionEntities = ['competition', 'marketplace_entry', 'news', 'gallery', 'playlist', 'match'];

const getPermission = (entity, id) => {
  if (!permissionEntities.includes(entity)) {
    throw new InvalidArgumentException(`getPermission received invalid argument ${entity} for parameter entity`);
  }
  return authorizedAPI.get(`/permissions?${entity}=${id}`);
};

const postClicks = (entity, id) => {
  return api.post(trackingBaseURL + '/clicks', {entity, id});
};

export {
  api,
  authorizedAPI,
  initHTTPResponseInterceptor,
  setAuthorizationHeader,
  getHTTPDebugParams,
  setHTTPDebugParams,
  fetchNextApiCall,
  getSearch,
  cancelPendingRequest,
  getTeam,
  getCompetition,
  getCompetitionHistory,
  getCompetitionStats,
  getCompetitionScorers,
  postClicks,
  getPermission,
};
