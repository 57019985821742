import {useDispatch, useSelector} from 'react-redux';
import {selectCurrentUser} from 'app/hooks/reduxCreateSelectorHooks';
import {useEffect, useState} from 'react';
import {updatePPID} from 'app/actions/currentUserActions';
import {selectAdsReady} from 'app/components/ads/AdCore';
import {createSelector} from 'reselect';
import {useConsentCheckForPPID} from 'app/helpers/consent';

const selectPPID = createSelector(
  state => state.currentUser.ppid,
  ppid => ppid
);

// Function to hash firebase user.uid to fit ppid requirements
// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await self.crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}

const useUpdatePPIDAdServer = () => {
  const adsReady = useSelector(selectAdsReady);
  const ppid = useSelector(selectPPID);
  const [noChange, setNoChange] = useState(true);

  useEffect(() => {
    // use state variable 'noChange' to avoid trigger an update for ppid
    // if adsReady is fired after firebase initialization
    if (adsReady && !noChange) {
      // use timeout to put the function to the end of the event loop so the new cookie value is available in window.checkPPID()
      setTimeout(() => window.checkPPID(), 0);
    }
    if (adsReady) {
      setNoChange(false);
    }
  }, [adsReady, ppid]);
};

const generateRandomPPID = dispatch => {
  if (typeof self?.crypto?.randomUUID === 'function') {
    const uuid = self.crypto.randomUUID();
    dispatch(updatePPID(uuid));
  }
};

const usePPID = () => {
  // NOTE: self.crypto.subtle and self.crypto.randomUUID only available for https
  const ppid = useSelector(selectPPID);
  const dispatch = useDispatch();
  const {user, initializing} = useSelector(selectCurrentUser);
  const consentChecked = useConsentCheckForPPID();

  useEffect(() => {
    if (!ppid && consentChecked) {
      generateRandomPPID(dispatch);
    }
  }, [consentChecked]);

  useEffect(() => {
    if (!initializing) {
      if (user?.uid && self?.crypto?.subtle) {
        digestMessage(user.uid).then(userIdHash => {
          dispatch(updatePPID(userIdHash));
        });
      }
    }
  }, [initializing, user, consentChecked]);

  useUpdatePPIDAdServer();
};

export default usePPID;
export {generateRandomPPID};
