import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import ServiceUnavailablePage from 'app/components/error/ServiceUnavailablePage';
import GenericServerErrorPage from 'app/components/error/GenericServerErrorPage';
import NotFoundPage from 'app/routes/error/NotFoundPage';
import {clearHttpError, clearHttpSubrouteError} from 'app/components/error/ErrorHandlerActions';
import {useLocation} from 'react-router';
import ForbiddenPage from 'app/routes/error/ForbiddenPage';
import NetworkErrorPage from 'app/routes/error/NetworkErrorPage';
import {TimeoutMessage} from 'app/components/error/TimeoutMessage';

const selectHTTPError = createSelector(
  state => state.httpError,
  httpError => httpError
);
const selectHTTPErrorSubroute = createSelector(
  state => state.httpErrorSubroute,
  httpErrorSubroute => httpErrorSubroute
);

const ErrorHandler = ({children, subroute}) => {
  const {status, text, message} = useSelector(subroute ? selectHTTPErrorSubroute : selectHTTPError);
  const dispatch = useDispatch();
  const location = useLocation();
  const getNotFound = useCallback(
    id => <NotFoundPage id={id} text={text} top={subroute ? '7.5rem' : undefined} />,
    [subroute]
  );
  const handleRateLimitError = useCallback(
    () => <ForbiddenPage top={subroute ? '7.5rem' : undefined} />,
    [subroute, location.pathname]
  );
  const getGenericServerError = useCallback(status => <GenericServerErrorPage status={status} />, []);

  useEffect(() => {
    const isError = ErrorMap[status] || (!status && TimeoutMessage.test(message));
    return () => {
      if (isError) {
        dispatch(clearHttpError());
        dispatch(clearHttpSubrouteError());
      }
    };
  }, [location.pathname, status, message]);

  const ErrorMap = {
    403: handleRateLimitError(),
    404: getNotFound('not-found-page'),
    410: getNotFound('gone-page'),
    503: <ServiceUnavailablePage />,
  };

  if (!status && TimeoutMessage.test(message)) {
    return <NetworkErrorPage top={subroute ? '7.5rem' : undefined} />;
  }

  if (!status) {
    return children;
  }

  const distinctErrorPage = ErrorMap[status];

  if (distinctErrorPage) {
    return distinctErrorPage;
  }

  if (status >= 400 && status < 500) {
    return getNotFound(`http-error-${status}`);
  }

  return getGenericServerError(status);
};

export {ErrorHandler};
