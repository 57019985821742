const initialData = [];

const HistoryReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'HISTORY_UPDATE':
      const copiedState = [...state];
      const actionKey = action.key ?? 'undefined';
      const historyExists = state.find(history => history.key === actionKey);
      const isSameStore = !!historyExists?.[action.data.store] ?? false;
      if (historyExists && isSameStore) {
        const index = copiedState.indexOf(historyExists);
        copiedState[index] = {
          key: actionKey,
          // Copy existing store for serverside calls with two api-calls (e.g. LeagueScorersPage)
          [action.data.store]: {...historyExists[action.data.store], ...action.data.data},
        };
        if (!(index === copiedState.length - 1)) {
          copiedState.push(copiedState.splice(index, 1)[0]);
        }
        return copiedState;
      } else if (historyExists && !isSameStore) {
        // Store several component states for same history key, e.g. MatchPage & MatchTickerPage
        const index = copiedState.indexOf(historyExists);
        copiedState[index] = {...copiedState[index], [action.data.store]: action.data.data};
        return copiedState;
      } else {
        const newState = [...copiedState, {key: actionKey, [action.data.store]: action.data.data}];
        if (newState.length > 5) {
          newState.shift();
        }
        return newState;
      }
    case 'HISTORY_CLEAR':
      return initialData;
    default:
      return state;
  }
};

export {HistoryReducer};
