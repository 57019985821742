const ssrRequestContext = {
  /**
   * Contains the IP address of the client that requested the SSR resource.
   * @type string|undefined
   */
  clientIp: undefined,
  /**
   * Used to allow re-using open HTTP connections across multiple SSR requests
   * @type https.Agent|undefined
   */
  httpsAgent: undefined,
  /**
   * HTTP-Cache-Control header for server-side-rendered pages (e.g. 'public, max-age=3600')
   * @type string|undefined
   */
  cacheControl: undefined,
};

export {ssrRequestContext};
