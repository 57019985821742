import styled, {css} from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import React, {Suspense} from 'react';
import {useSelector} from 'react-redux';
import {SuperbannerStyle} from 'app/components/ads/adStylePlaceholders';
import {selectHydrationFinished} from 'app/hooks/reduxCreateSelectorHooks';
const PartnerTeaser = React.lazy(() => import('app/components/partner/PartnerTeaser'));
const SkyscraperAdSlot = React.lazy(() => import('app/components/ads/SkyscraperAdSlot.js'));
const SuperbannerAdSlot = React.lazy(() => import('app/components/ads/SuperbannerAdSlot'));
const MobileBannerAdSlot = React.lazy(() => import('app/components/ads/MobileBannerAdSlot'));

const FlexContainer = styled.div`
  display: flex;
`;

const Content = styled.div`
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);
  padding-top: ${SIZES['8']};
  padding-bottom: ${props => (props.contentPaddingBottom ? props.contentPaddingBottom : 0)};
  background-color: ${props => (props.bgColor ? COLORS.lighterGrey : COLORS.white)};
  max-width: ${props => props.theme.desktopContentWidth}px;
  ${props => props.theme.desktopLayout`
    padding: 0 3rem 1rem;

    // increase padding if background is white
    ${props =>
      (props.bgColor || props.noContentPadding) &&
      css`
        padding: 0;
      `};

     // show border for content on grey background
     ${props =>
       !props.bgColor &&
       css`
         border: 1px solid ${COLORS.lightGrey};
       `}
   `}
`;

const ContentAdWrapper = styled.div`
  // use padding instead of margin to avoid cls for body
  padding-top: ${props => `calc(${props.top} + env(safe-area-inset-top))`};
  ${props => props.theme.desktopLayout`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: ${props => props.theme.desktopContentWidth}px;
    // remove padding if there is no superbanner
     ${props =>
       props.hasNoPadding &&
       css`
         // use padding instead of margin to avoid cls for body
         padding-top: env(safe-area-inset-top);
       `};
    `};
  // fix margin of superbanner collapsing with parent components
  // https://stackoverflow.com/questions/27829250/why-doesnt-a-childs-margin-affect-a-parents-height
  display: flex;
  flex-direction: column;
`;

const ContentWithStickyBanner = styled(Content)`
  padding-bottom: 4rem;
  ${props => props.theme.desktopLayout`
    padding-bottom: 1.5rem;
  `}
`;

const ContentAdDesktop = React.forwardRef((props, ref) => {
  const hasNoPadding = !props.noAds && !props.skyscraperOnly;
  const hydrationFinished = useSelector(selectHydrationFinished);

  const superbanner =
    props.skyscraperOnly || props.noAds ? null : hydrationFinished ? (
      <Suspense fallback={<SuperbannerStyle top={props.top} />}>
        <SuperbannerAdSlot top={props.top} />
      </Suspense>
    ) : (
      <SuperbannerStyle top={props.top} />
    );
  const skyscraper =
    props.superbannerOnly || props.noAds ? null : hydrationFinished ? (
      <Suspense fallback={<div />}>
        <SkyscraperAdSlot skyscraperOnly={props.skyscraperOnly} noTabs={props.noTabs} />
      </Suspense>
    ) : (
      <div />
    );

  const partnerTeaser = props.noPartnerTeaser ? null : hydrationFinished ? (
    <Suspense fallback={<div />}>
      <PartnerTeaser bgColor={props.bgColor} />
    </Suspense>
  ) : (
    <div />
  );

  let mobileBanner = null;
  if (props.withMobileBanner) {
    if (hydrationFinished) {
      mobileBanner = (
        <Suspense fallback={<div />}>
          <MobileBannerAdSlot />
        </Suspense>
      );
    } else {
      mobileBanner = <div />;
    }
  }
  return (
    <ContentAdWrapper id='fupa-content' top={props.top} hasNoPadding={hasNoPadding}>
      {superbanner}
      {skyscraper}
      <Content
        {...props}
        contentPaddingBottom={props.contentPaddingBottom}
        noContentPadding={props.noContentPadding}
        ref={ref}>
        {mobileBanner}
        {partnerTeaser}
        {props.children}
      </Content>
      {props.fabBtn}
    </ContentAdWrapper>
  );
});

const StyledTypography = styled(Typography)`
  ${props =>
    props.lineHeight &&
    `
   line-height: ${SIZES[props.lineHeight]};
  `}
  ${props =>
    props.useOverflow &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${props =>
    props.textAlign &&
    `
    text-align: ${props.textAlign}
  `}
  ${props =>
    props.isDeactivated &&
    `
    color: ${COLORS.darkGrey};
  `}
`;

export {FlexContainer, Content, ContentAdDesktop, ContentAdWrapper, ContentWithStickyBanner, StyledTypography};
