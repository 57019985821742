import React, {useEffect, useState, Suspense, useRef} from 'react';
import {AppBarWrapper} from 'app/components/appBar/AppBarWrapper';
import {renderRoutes} from 'react-router-config';
import {NavBar} from 'app/components/navBar/navBar';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectCurrentUser,
  selectFetchStates,
  selectDistrict,
  selectSsrRendered,
  selectVariant,
  selectHydrationFinished,
  selectAuthDialog,
} from 'app/hooks/reduxCreateSelectorHooks';
import {useHistory, useLocation} from 'react-router';
import styled from 'styled-components';
import {ErrorHandler} from 'app/components/error/ErrorHandler';
import {getHTTPDebugParams, initHTTPResponseInterceptor, setHTTPDebugParams} from 'app/services/api';
import {Helmet} from 'react-helmet-async';
import {useAdServices} from 'app/components/ads/useAdServices';
import {setHydrationFinished, setSsrRendered, updateVariant} from 'app/actions/sessionActions';
import appVersion from 'app/helpers/appVersion';
import {useConsent} from 'app/helpers/consent';
import {sendEventToGA4, setDimensionToGA4} from 'app/helpers/gtagHelpers';
import {useEffectAfterMount} from 'app/hooks/dataHooks';
import {captureSentryException} from 'app/services/sentryLogging';
import {checkCurrentAppRevision} from 'app/services/getAppRevision';
import useTokenExpirationCheck from 'app/hooks/useTokenExpirationCheck';
import {usePageViewEvent} from 'app/hooks/usePageViewEvent';
import {useNative} from 'app/contexts/NativeContext';
import {useExperimental} from 'app/hooks/useExperimental';
import {updatePullToRefresh} from 'app/actions/pullToRefreshActions';
import {initSessionReplayIntegration} from 'app/hooks/useSentrySessionReplay';
import LocalStorageManager from 'app/services/web-storage/LocalStorageManager';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import ExpFeatureWebInterstitial from 'app/models/experimentalFeature/ExpFeatureWebInterstitial';
import ExperimentalFeatureManager from 'app/models/experimentalFeature/ExperimentalFeatureManager';
const FuPaSnackbar = React.lazy(() => import('app/components/snackbar/Snackbar'));
const AppShellBanner = React.lazy(() => import('app/components/banner/AppShellBanner'));
const IvwLoader = React.lazy(() => import('app/components/lazyLoadWrapper/ivw/IvwLoader'));
const ATTLoader = React.lazy(() => import('app/components/lazyLoadWrapper/att/ATTLoader'));
const LiverampLoader = React.lazy(() => import('app/components/lazyLoadWrapper/liveramp/LiverampLoader'));
const OnboardingFlow = React.lazy(() => import('app/components/onboardingFlows/OnboardingFlow'));

const MobileNavBar = styled(NavBar)`
  ${props => props.theme.desktopLayout`
      display: none
   `}
`;
const AppShell = ({routes}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {initializing, user, claims, notificationPermission, fuPaUser, follows} = useSelector(selectCurrentUser);
  const district = useSelector(selectDistrict);
  const ssrRendered = useSelector(selectSsrRendered);
  const [key, setKey] = useState(0);
  const nativeInfo = useNative();
  const {isNativeApp, platform} = nativeInfo;
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // avoid re-fetching immediately on the client
            staleTime: 30 * 1000,
          },
        },
      })
  );

  const experimental = useExperimental();
  useEffect(() => {
    console.log('### nativeInfo', JSON.stringify(nativeInfo));
  }, [nativeInfo]);

  useEffect(() => {
    LocalStorageManager.getInstance().clearExpired();
  }, []);

  useEffect(() => {
    window.pullToRefresh = () => {
      dispatch(updatePullToRefresh(true));
      setKey(prevKey => prevKey + 1);
    };
  }, []);

  const {followsFetched} = useSelector(selectFetchStates);
  const authDialog = useSelector(selectAuthDialog);
  const [connectionStatus, setConnectionStatus] = useState(null);
  const variant = useSelector(selectVariant);
  // const adMonetizer = useSelector(selectAdMonetizer);
  const hydrationFinished = useSelector(selectHydrationFinished);

  const isInitialLocation = useRef(true);

  const {handleHelmetClientStateChange} = usePageViewEvent();

  useEffectAfterMount(() => {
    if (isInitialLocation.current) {
      dispatch(setSsrRendered(false));
      isInitialLocation.current = false;
    }
  }, [location]);

  // useEffect(() => {
  //   // AB-Test ad monetizer
  //   if (!ssrRendered) {
  //     //  reload page if ad monetizer changes
  //     if (adMonetizer) {
  //       window.location.reload();
  //     }
  //   }
  // }, [adMonetizer]);

  useEffect(() => {
    dispatch(setHydrationFinished());
  }, []);

  useEffect(() => {
    checkCurrentAppRevision();
  }, []);

  // Reminder: Execution order of useEffect while hydrating from SSR: fist child, than parent
  // First effects of AppShell.js than app.js
  useEffect(() => {
    const initializeFirebase = async () => {
      const {initFirebase} = await import('app/services/firebase');
      initFirebase(dispatch, nativeInfo);
    };

    // call the function
    initializeFirebase().catch(e =>
      captureSentryException(e, {extra: {errorLocation: 'Initializing Firebase in AppShell'}})
    );
  }, []);
  useAdServices();

  const meta = [
    {
      name: 'robots',
      content: 'noindex',
    },
  ];

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', e => {
      e.userChoice.then(choiceResult => {
        sendEventToGA4('add_2_homescreen', {choice_result: choiceResult.outcome});
      });
    });
  }, []);

  useEffect(() => {
    if (district) {
      setDimensionToGA4({user_properties: {fupa_region: district.region?.slug, fupa_district: district.slug}});
    }
  }, [district]);

  useEffect(() => {
    let app = 'false';
    if (isNativeApp) {
      app = platform;
    }
    const ga4Platform = app === 'false' ? 'web' : app;
    setDimensionToGA4({user_properties: {fupa_platform: ga4Platform}});
  }, []);

  function updateConnectionStatus() {
    setConnectionStatus(navigator.connection.effectiveType);
  }

  useEffect(() => {
    if (navigator?.connection?.effectiveType) {
      setConnectionStatus(navigator.connection.effectiveType);
      navigator.connection.addEventListener('change', updateConnectionStatus);
    } else {
      setConnectionStatus('not supported');
    }
  }, []);

  useEffect(() => {
    if (connectionStatus) {
      setDimensionToGA4({user_properties: {fupa_connection_status: connectionStatus}});
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (!initializing) {
      const userRole = fuPaUser?.userRole ? fuPaUser.userRole : 'guest';
      setDimensionToGA4({user_properties: {fupa_user_role: userRole}});
    }
  }, [initializing, fuPaUser]);

  useEffect(() => {
    if (!ssrRendered) {
      // Set variant only for csr navigation otherwise variant and displayed component variant does not match,
      // because for init call the components need to render with 'default' to avoid caching test versions
      const variant = Math.random() < 0.5 ? 'A' : 'B';
      dispatch(updateVariant(variant));
    }
  }, [ssrRendered]);

  useEffect(() => {
    setDimensionToGA4({user_properties: {fupa_variant: variant}});
  }, [variant]);

  useConsent();

  useEffect(() => {
    setDimensionToGA4({user_properties: {fupa_version: appVersion}});
  }, []);

  useEffect(() => {
    if (followsFetched) {
      const countFollows = follows.length;
      setDimensionToGA4({user_properties: {fupa_follow_count: countFollows}});
    }
  }, [follows, followsFetched]);

  useEffect(() => {
    if (nativeInfo.isNativeApp && followsFetched && follows.length && notificationPermission === 'default') {
      nativeInfo.showNativeNotificationPermissionDialog.handler();
    }
  }, [follows, followsFetched, notificationPermission, nativeInfo]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const debugQuery = query.get('debug');
    if (!debugQuery) {
      // get current params from api-config
      const apiDefaultParams = getHTTPDebugParams();
      if (apiDefaultParams?.debug && apiDefaultParams.debug.includes('nomaintenance')) {
        // do not remove nomaintenance debug, which should be used for the whole session
        setHTTPDebugParams({debug: 'nomaintenance'});
      } else {
        // remove debug-queries if the url changes, and the debug query in url is not set
        setHTTPDebugParams(undefined);
      }
    } else {
      // set debug params to every api request, e.g. 'debug=nocache,nomaintenance'
      setHTTPDebugParams({debug: debugQuery});
    }
  }, [location.search]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const debugQuery = query.get('debug');
    if (debugQuery === 'replay') {
      initSessionReplayIntegration(1);
    }
  }, [location.search]);

  useEffect(() => {
    const lastTimestampElement = document.getElementById('last-timestamp');
    try {
      lastTimestampElement.textContent = String(new Date().getTime());
    } catch (error) {
      captureSentryException(error);
    }
  }, [location.pathname]);

  useEffect(() => {
    initHTTPResponseInterceptor();
  }, []);

  useEffect(() => {
    if (!initializing && user && Object.keys(user).length) {
      const initFirebaseMessaging = async () => {
        const {initMessaging} = await import('app/services/firebaseMessaging');
        initMessaging(dispatch, notificationPermission, nativeInfo);
      };

      // call the function
      initFirebaseMessaging().catch(e =>
        captureSentryException(e, {extra: {errorLocation: 'Initializing Firebase Messaging in AppShell'}})
      );
    }
  }, [initializing]);

  useTokenExpirationCheck(user, claims);

  // Handles redirect to AuthService to complete profile and verify user email
  // expect if user has used sign up flow within auth dialog while session
  useEffect(() => {
    if (authDialog) {
      return;
    }
    if (Object.keys(user).length && (!claims.u || (claims.u && !claims.email_verified))) {
      window.location.href = `/auth/complete-profile?redirectUrl=${encodeURIComponent(location.pathname)}`;
    }
  }, [user, claims]);

  // Setup web interstitial flags
  useEffect(() => {
    const guestUser = !initializing && !user?.uid;
    if (experimental || guestUser) {
      window.fupa_web_interstitial = true;
    }
    if (experimental) {
      ExperimentalFeatureManager.getInstance().enable(new ExpFeatureWebInterstitial());
    }
  }, [experimental, initializing, user]);

  const isLandingPage = location.pathname === '/start' || location.pathname === '/';
  const isVideoPage = location.pathname.startsWith('/tv/');
  const showMobilNavBar = isLandingPage || isVideoPage ? null : <MobileNavBar />;

  const banner = hydrationFinished ? (
    <Suspense fallback={<div />}>
      <FuPaSnackbar />
    </Suspense>
  ) : (
    <div />
  );
  const snackbar = hydrationFinished ? (
    <Suspense fallback={<div />}>
      <AppShellBanner />
    </Suspense>
  ) : (
    <div />
  );
  const ivw = hydrationFinished ? (
    <Suspense fallback={null}>
      <IvwLoader />
    </Suspense>
  ) : null;

  const att = hydrationFinished ? (
    <Suspense fallback={null}>
      <ATTLoader />
    </Suspense>
  ) : null;

  const liveramp = hydrationFinished ? (
    <Suspense fallback={null}>
      <LiverampLoader />
    </Suspense>
  ) : null;

  const onboarding = hydrationFinished ? (
    <Suspense fallback={<div />}>
      <OnboardingFlow />
    </Suspense>
  ) : null;

  return (
    <React.Fragment>
      {ivw}
      {att}
      {liveramp}
      <Helmet meta={meta} onChangeClientState={handleHelmetClientStateChange} />
      <AppBarWrapper user={user} />
      <ErrorHandler>
        <QueryClientProvider client={queryClient}>
          <React.Fragment key={key}>
            {renderRoutes(routes, {
              initializing,
            })}
          </React.Fragment>
        </QueryClientProvider>
      </ErrorHandler>
      {onboarding}
      {banner}
      {snackbar}
      {showMobilNavBar}
    </React.Fragment>
  );
};

export {AppShell};
