import {createSelector} from 'reselect';

// FIXME: Re-renders page for every change of bool header.collapsed, not only when header.title or header.parent change
const selectHeader = createSelector(
  state => state.header,
  header => {
    return {
      headerTitle: header.title,
      headerParentName: header.parent.name,
    };
  }
);

const selectCollapsedAppBar = createSelector(
  state => state.header,
  header => header.collapsed
);

const selectCurrentUser = createSelector(
  state => state.currentUser,
  currentUser => currentUser
);

const selectAuthState = createSelector(
  state => state.currentUser,
  currentUser => {
    return currentUser.user !== null && !!Object.keys(currentUser.user).length;
  }
);

const selectFetchStates = createSelector(
  state => state.currentUser,
  currentUser => {
    const {followsFetched, devicesFetched} = currentUser;
    return {followsFetched, devicesFetched};
  }
);

/**
 * @deprecated use reduxSelectDistrict instead
 */
const selectDistrict = createSelector(
  state => state.session,
  session => session.district
);

const selectFollowedEntities = createSelector(
  state => state.currentUser,
  currentUser => currentUser.follows
);

const selectSsrRendered = createSelector(
  state => state.session.ssrRendered,
  ssrRendered => ssrRendered
);

const selectPreviewMode = createSelector(
  state => state.session.previewMode,
  previewMode => previewMode
);

const selectVariant = createSelector(
  state => state.session.variant,
  variant => variant
);

const selectAdMonetizer = createSelector(
  state => state.session.adMonetizer,
  adMonetizer => adMonetizer
);

const selectHydrationFinished = createSelector(
  state => state.session.hydrationFinished,
  hydrationFinished => hydrationFinished
);

const selectWebsocketData = createSelector(
  state => state.websocket,
  websocket => websocket
);

const selectAuthDialog = createSelector(
  state => state.session.authDialog,
  authDialog => authDialog
);

export {
  selectHeader,
  selectCollapsedAppBar,
  selectCurrentUser,
  selectAuthState,
  selectFetchStates,
  selectDistrict,
  selectFollowedEntities,
  selectSsrRendered,
  selectPreviewMode,
  selectVariant,
  selectAdMonetizer,
  selectHydrationFinished,
  selectWebsocketData,
  selectAuthDialog,
};
