const storageAvailable = type => {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

const getElementFromStorage = (storage, name) => {
  if (storageAvailable(storage)) {
    return window[storage].getItem(name);
  } else {
    return null;
  }
};

const setElementToStorage = (storage, name, value) => {
  if (storageAvailable(storage)) {
    window[storage].setItem(name, value);
  }
};

const removeElementFromStorage = (storage, name) => {
  if (storageAvailable(storage)) {
    window[storage].removeItem(name);
  }
};

/**
 * @deprecated use WebStorageManager instead
 */
const getElementFromLocalStorage = name => {
  return getElementFromStorage('localStorage', name);
};

/**
 * @deprecated use WebStorageManager instead
 */
const setElementToLocalStorage = (name, value) => {
  setElementToStorage('localStorage', name, value);
};

/**
 * @deprecated use WebStorageManager instead
 */
const removeElementFromLocalStorage = name => {
  removeElementFromStorage('localStorage', name);
};

const getElementFromSessionStorage = name => {
  return getElementFromStorage('sessionStorage', name);
};

const setElementToSessionStorage = (name, value) => {
  setElementToStorage('sessionStorage', name, value);
};

const removeElementFromSessionStorage = name => {
  removeElementFromStorage('sessionStorage', name);
};

export {
  getElementFromLocalStorage,
  setElementToLocalStorage,
  removeElementFromLocalStorage,
  getElementFromSessionStorage,
  setElementToSessionStorage,
  removeElementFromSessionStorage,
};
