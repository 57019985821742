import UAParser from 'ua-parser-js';

const getAge = (birthday, clean) => {
  let today = new Date();
  let birthDate = new Date(birthday);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return clean ? age : age + ' Jahre';
};

const round = (value, precision) => {
  const val = isNaN(value) ? 0 : value;
  const multiplier = Math.pow(10, precision || 0);
  const valueToFormat = Math.round(val * multiplier) / multiplier;
  return new Intl.NumberFormat('de-DE').format(valueToFormat);
};

const parseNumber = number => {
  if (Math.abs(number) > 999999) {
    const value = Math.sign(number) * (Math.abs(number) / 1000000).toFixed(2);
    const formattedValue = new Intl.NumberFormat('de-DE').format(value);
    return formattedValue + ' Mio';
  } else if (Math.abs(number) > 999) {
    const value = Math.sign(number) * (Math.abs(number) / 1000).toFixed(1);
    const formattedValue = new Intl.NumberFormat('de-DE').format(value);
    return formattedValue + 'k';
  } else {
    return round(number);
  }
};

const mapsSelector = venue => {
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      `${venue.name}, ${venue.street}, ${venue.postcode} ${venue.city}`
    )}`
  );
};

const getTeamRootSlug = team => {
  return team.teams?.find(item => item.club_id === team.club.id)?.slug;
};

const getPlayerShortName = player => {
  return player.firstName ? `${player.firstName.charAt(0)}. ${player.lastName}` : `${player.lastName}`;
};

const numberWithThousandSeparator = x => {
  try {
    return new Intl.NumberFormat('de-DE').format(x);
  } catch (e) {
    return x;
  }
};

const escapeRegExp = string => {
  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const COMPETITION_IMAGE_FALLBACK = 'PEbMHNJZxotP';

const parser = new UAParser();
const getUserAgentData = () => {
  return {
    platform: parser.getOS().name,
    browser: parser.getBrowser().name || '',
    deviceType: parser.getDevice().type || 'desktop',
  };
};

const convertToArrayWithUniqueValues = array => {
  return Array.from(new Set(array));
};

export {
  getAge,
  round,
  parseNumber,
  mapsSelector,
  getTeamRootSlug,
  getPlayerShortName,
  numberWithThousandSeparator,
  escapeRegExp,
  COMPETITION_IMAGE_FALLBACK,
  getUserAgentData,
  convertToArrayWithUniqueValues,
};
