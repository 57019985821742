import {useIsDesktop} from 'app/components/ads/useIsDesktop';
import {useEffect} from 'react';
import {updateSkyscraperSkipping} from 'app/actions/skyscraperActions';
import {useDispatch} from 'react-redux';

const sodAdvertiserId = 5069255654;
const skinAdvertiserIdsQM = [sodAdvertiserId, 24735278, 4736729463, 4977350115, 4780084796, 5318547530]; // sorry for not using speaking values, ids are provided by QM
const useSkipSkyscraper = () => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  useEffect(() => {
    if (isDesktop) {
      window.googletag.cmd.push(() => {
        window.googletag.pubads().addEventListener('slotRenderEnded', function (event) {
          const slot = event.slot;
          const slotId = slot.getSlotElementId();
          const advID = event.advertiserId;
          if (slotId === 'ad-Superbanner') {
            if (skinAdvertiserIdsQM.includes(advID)) {
              dispatch(updateSkyscraperSkipping(true));
            } else {
              dispatch(updateSkyscraperSkipping(false));
            }
          }
        });
      });
    }
  }, [isDesktop]);
};

export default useSkipSkyscraper;
