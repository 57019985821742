import React, {useState, useEffect, Suspense} from 'react';
import styled from 'styled-components';
import {Avatar} from 'app/components/avatar/Avatar';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectFollowedEntities} from 'app/hooks/reduxCreateSelectorHooks';
const Badge = React.lazy(() => import('@mui/material/Badge'));

const SCAvatar = styled(Avatar)`
  margin-left: 0.75rem;
  cursor: pointer;
`;

const AvatarWithBadge = React.forwardRef((props, ref) => {
  const {messagingDeviceToken, pushSupport, notificationPermission, devices, initializing, fuPaUserFetching} =
    useSelector(selectCurrentUser);
  const listOfFollowedEntities = useSelector(selectFollowedEntities);
  const hasPushedEntity = !!listOfFollowedEntities.find(entity => entity.push);
  const [showBadge, setShowBadge] = useState(false);

  useEffect(() => {
    const showNoDeviceBanner =
      !devices.length && (notificationPermission === 'denied' || notificationPermission === 'deactivated');
    const showNewDeviceBanner = pushSupport && !messagingDeviceToken && notificationPermission === 'default';

    setShowBadge(showNewDeviceBanner || showNoDeviceBanner);
  });

  const AvatarComp = <SCAvatar ref={ref} {...props} width={32} loading={initializing || fuPaUserFetching} />;

  if (hasPushedEntity && showBadge) {
    return (
      <Suspense fallback={<div />}>
        <Badge color='warning' overlap='circular' badgeContent=' ' variant='dot'>
          {AvatarComp}
        </Badge>
      </Suspense>
    );
  } else {
    return AvatarComp;
  }
});

export {AvatarWithBadge};
