import {useRef} from 'react';

// https://dev.to/bytebodger/constructors-in-functional-components-with-hooks-280m
const useConstructor = (callBack = () => {}) => {
  // Code that runs before anything else in the life-cycle of a component.
  // Constructor: Occurs ONCE, BEFORE the initial render.
  // Effects (useEffect, useLayoutEffect) fire AFTER rendering.
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) {
    return;
  }
  callBack();
  hasBeenCalled.current = true;
};

export {useConstructor};
