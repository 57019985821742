// Used to reduce init js but set styles correctly for ad container to avoid shifts

import styled from 'styled-components';

const SuperbannerStyle = styled.div`
  &&& {
    display: none;
    ${props =>
      props.theme.desktopLayout`
        display: flex;
        background-color: transparent;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding: 0;
        margin-top: calc(${props => props.top} + 1rem);
        margin-bottom: 1rem;
        margin-left: 0;
        overflow: hidden;
        max-height: 250px;
        height: 250px;
    `}
  }
`;

export {SuperbannerStyle};
