const initialData = {};

const HTTPErrorReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'HTTP_ERROR_UPDATE':
      return action.data;
    case 'HTTP_ERROR_CLEAR':
      return {...initialData};
    default:
      return state;
  }
};

const HTTPErrorSubrouteReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'HTTP_ERROR_SUBROUTE_UPDATE':
      return action.data;
    case 'HTTP_ERROR_SUBROUTE_CLEAR':
      return {...initialData};
    default:
      return state;
  }
};

export {HTTPErrorReducer, HTTPErrorSubrouteReducer};
