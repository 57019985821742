function updateHttpError(data) {
  return {
    type: 'HTTP_ERROR_UPDATE',
    data,
  };
}

function clearHttpError() {
  return {
    type: 'HTTP_ERROR_CLEAR',
  };
}

function updateHttpErrorSubroute(data) {
  return {
    type: 'HTTP_ERROR_SUBROUTE_UPDATE',
    data,
  };
}

function clearHttpSubrouteError() {
  return {
    type: 'HTTP_ERROR_SUBROUTE_CLEAR',
  };
}

export {updateHttpError, clearHttpError, updateHttpErrorSubroute, clearHttpSubrouteError};
