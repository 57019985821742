import axios from 'axios';
import {getUserAgentData} from 'app/helpers/helpers';
import {CLIENT_API_RESPONSE_TIMEOUT} from 'app/services/api';

const baseURL = process.env.NOTIFICATION_URL;

const notificationApi = axios.create({baseURL, timeout: CLIENT_API_RESPONSE_TIMEOUT});

notificationApi.interceptors.request.use(
  async config => {
    const {tokenValidationForXHR} = await import('app/services/firebase');
    const token = await tokenValidationForXHR();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const putDevice = messagingToken => {
  const data = getUserAgentData();

  return notificationApi.put(`/devices/${messagingToken}`, data);
};

const deleteDevice = messagingToken => {
  return notificationApi.delete(`/devices/${messagingToken}`);
};

const getDevices = () => {
  return notificationApi.get('/devices');
};

export {putDevice, deleteDevice, getDevices};
