import {useLocation} from 'react-router';
import {useSelector} from 'react-redux';
import {
  selectCurrentUser,
  selectDistrict,
  selectPreviewMode,
  selectSsrRendered,
  // selectVariant,
} from 'app/hooks/reduxCreateSelectorHooks';
import {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import appVersion from 'app/helpers/appVersion';
import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import {useIsDesktop} from 'app/components/ads/useIsDesktop';
import Path from 'app/models/Path';
import {useNative} from 'app/contexts/NativeContext';

const getTargetingSource = (platform, deviceType) => {
  const isAndroid = platform === 'android';
  const isIos = platform === 'ios';
  if (isAndroid) {
    return 'app/android';
  } else if (isIos) {
    return 'app/ios';
  } else {
    return `web/${deviceType}`;
  }
};

const setTargetingParams = targeting => {
  window.googletag.cmd.push(() => {
    Object.keys(targeting).map(key => {
      const value = targeting[key];
      if (value) {
        window.googletag.pubads().setTargeting(key, value);
      }
    });
  });
};

const clearTargetingParams = targeting => {
  window.googletag.cmd.push(() => {
    const keys = window.googletag.pubads().getTargetingKeys();
    Object.keys(targeting).map(key => {
      if (keys.includes(key)) window.googletag.pubads().clearTargeting(key);
    });
  });
};

const getTargetingEntityFromPath = path => path.split('/').splice(1, 2).join('/');

const useAdDefaultTargeting = () => {
  // Skip for SSR
  if (typeof window === 'undefined') {
    return;
  }

  const location = useLocation();
  const selectedDistrict = useSelector(selectDistrict);
  const isHydrating = useSelector(selectSsrRendered);
  const {fuPaUser} = useSelector(selectCurrentUser);
  // const variant = useSelector(selectVariant);
  const isDesktop = useIsDesktop();
  const deviceType = isDesktop ? 'desktop' : 'mobile';
  const previewMode = useSelector(selectPreviewMode);
  const pageViewCount = useRef(1);
  const previousPath = useRef(null);
  const {platform} = useNative();

  // set pageview_count based on location change but ignore hash changes (e.g. photos page)
  useEffect(() => {
    setTargetingParams({pageview_count: pageViewCount.current.toString()});
    pageViewCount.current += 1;
  }, [location.search, location.pathname]);

  // useEffect(() => {
  //   setTargetingParams({variant});
  // }, [variant]);

  // set region + district
  useEffect(() => {
    const sessionData = {
      district: selectedDistrict?.slug ?? 'global',
      region: selectedDistrict?.region?.slug ?? 'global',
    };
    setTargetingParams(sessionData);
  }, [selectedDistrict]);

  // set ssr
  useEffect(() => {
    setTargetingParams({ssr: isHydrating.toString()});
  }, [isHydrating]);

  const setTargetingOnRootPathLevel = useCallback(() => {
    const rootPath = location.pathname.split('/')?.[1];

    const source = getTargetingSource(platform, deviceType);

    const targeting = {
      type: rootPath,
      source,
      version: previewMode ? 'preview' : appVersion,
    };
    setTargetingParams(targeting);
  }, [isHydrating, deviceType, platform, previewMode, location.pathname]);

  const handleRootSpecificSiteTargeting = useCallback(pathname => {
    const path = new Path(pathname);
    if (path.isClub()) {
      setTargetingParams({club: path.slug});
    } else if (path.isCompetition()) {
      setTargetingParams({competition: path.slug});
    } else {
      clearTargetingParams({club: '', competition: ''});
    }
  }, []);

  useEffect(() => {
    const currentPath = getTargetingEntityFromPath(location.pathname);
    if (previousPath.current !== currentPath) {
      setTargetingOnRootPathLevel();
      handleRootSpecificSiteTargeting(location.pathname);
      previousPath.current = currentPath;
    }
  }, [location.pathname]);

  // set template, subType for every location change
  useEffect(() => {
    const {subType, template} = generateUniqueTemplateKey(location.pathname);
    const targeting = {subType, template};
    setTargetingParams(targeting);
  }, [location]);

  // user_role + user_permission
  useEffect(() => {
    if (fuPaUser?.userRole) {
      setTargetingParams({user_role: fuPaUser.userRole});
    } else {
      setTargetingParams({user_role: 'guest'});
    }

    if (fuPaUser?.userPermissions) {
      setTargetingParams({user_permissions: fuPaUser.userPermissions});
    }
  }, [fuPaUser]);
};

const useSiteSpecificAdTargeting = (targeting, dependency = []) => {
  const testValue = currentValue => {
    return Array.isArray(currentValue) ? Object.values(currentValue).every(testValue) : !currentValue;
  };
  useLayoutEffect(() => {
    if (Object.values(targeting).every(testValue)) {
      return;
    }
    setTargetingParams(targeting);
    // use layout effect to clear targeting params on unmount synchronously
    // and run after useEffect of useAdDefaultTargeting (handleRootSpecificSiteTargeting)
    return () => {
      clearTargetingParams(targeting);
    };
  }, dependency);
};

export {useAdDefaultTargeting, useSiteSpecificAdTargeting};
