function changeNavBar(tabIndex) {
  return {
    type: 'NAVBAR_CHANGE',
    id: tabIndex,
  };
}

function changeNavBarLinks(data) {
  return {
    type: 'NAVBAR_LINK_CHANGE',
    data,
  };
}

function updateHistoryMap(data) {
  return {
    type: 'UPDATE_HISTORY_MAP',
    data,
  };
}

export {changeNavBar, changeNavBarLinks, updateHistoryMap};
