const initialData = {
  message: '',
  action: {
    label: '',
    link: '',
    target: '',
  },
};

const SnackbarReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'SNACKBAR_UPDATE':
      return {...action.data};
    case 'SNACKBAR_CLEAR':
      return {...initialData};
    default:
      return state;
  }
};

export {SnackbarReducer};
