import {useEffectAfterMount} from 'app/hooks/dataHooks';
import {useCallback, useRef} from 'react';
import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {generateTrackingService} from 'app/helpers/trackingTrigger';
import {useSelector} from 'react-redux';
import {selectDistrict} from 'app/hooks/reduxCreateSelectorHooks';
import {useLocation} from 'react-router';
import {StateUpdate} from 'react-helmet-async';

export const usePageViewEvent = () => {
  const district = useSelector(selectDistrict);
  const location = useLocation();
  const pushGA4 = generateTrackingService();
  const previousPageTitle = useRef<string | null>(null);

  const pageViewEvent = useCallback((pathname: string, search: string, hash: string, title: string) => {
    const page = pathname + search + hash;
    const {template} = generateUniqueTemplateKey(pathname);
    pushGA4({page_title: title, page_location: document.location, content_group: template}, page, event =>
      sendEventToGA4('page_view', event)
    );
  }, []);

  useEffectAfterMount(() => {
    triggerPageViewEvent(document.title);
  }, [location.pathname, location.search, location.hash]);

  const triggerPageViewEvent = useCallback(
    (newPageTitle: string) => {
      if (district.id || (!district.id && !/selectedDistrictSlug/.test(document.cookie))) {
        pageViewEvent(location.pathname, location.search, location.hash, newPageTitle);
      }
    },
    [location.pathname, location.search, location.hash, district.id]
  );

  const handleHelmetClientStateChange = (clientState: StateUpdate) => {
    const newPageTitle = clientState.title;
    if (previousPageTitle.current === newPageTitle) {
      return;
    }
    previousPageTitle.current = newPageTitle;
    triggerPageViewEvent(newPageTitle);
  };

  return {handleHelmetClientStateChange};
};
