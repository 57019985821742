function updateHistory(key, data) {
  return {
    type: 'HISTORY_UPDATE',
    key,
    data,
  };
}

function clearHistory() {
  return {
    type: 'HISTORY_CLEAR',
  };
}

export {updateHistory, clearHistory};
