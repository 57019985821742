const initialData = {
  key: {
    init: '',
    current: '',
  },
  previousPathname: '',
  size: 'large',
  title: '',
  variant: 'h1',
  parent: {
    name: '',
    entity: '',
    slugs: {},
    variant: 'h2',
  },
  season: '',
  follow: false,
  entity: {},
  collapsed: false,
  autoScroll: false,
};

const headerReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'HEADER_STYLE_CHANGE':
      return {...state, size: action.value.size};
    case 'HEADER_FOLLOWING':
      return {...state, follow: action.value};
    case 'HEADER_TEXT_CHANGE':
      let {title, variant, parent} = action.value;
      let parentVariant = action.value.parent?.variant || state.parent.variant;
      parent = parent || state.parent;
      parent.variant = parentVariant;
      title = title || state.title;
      variant = variant || state.variant;
      return {
        ...state,
        title,
        variant,
        parent,
        season: action.value.season || '',
      };
    case 'HEADER_ENTITY_CHANGE':
      return {...state, entity: action.entity};
    case 'HEADER_TOGGLE':
      return {...state, collapsed: action.value};
    case 'HEADER_AUTO_SCROLL':
      return {...state, autoScroll: action.value};
    case 'HEADER_RESET':
      return {...initialData, key: state.key, previousPathname: action.data.pathname};
    case 'HEADER_KEY_UPDATE':
      return {...state, key: {...state.key, current: action.data.key}};
    case 'HEADER_PREVIOUS_PATHNAME_UPDATE':
      return {...state, previousPathname: action.data.pathname};
    default:
      return state;
  }
};

export {headerReducer};
