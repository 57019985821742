// PARTNER_UPDATE
function setPartner(data) {
  return {
    type: 'PARTNER_UPDATE',
    data,
  };
}

// PARTNER_CLEAR
function clearPartner() {
  return {
    type: 'PARTNER_CLEAR',
  };
}

export {setPartner, clearPartner};
