const PullToRefreshReducer = (state = false, action) => {
  switch (action.type) {
    case 'UPDATE_PULL_TO_REFRESH':
      return action.data;
    default:
      return state;
  }
};

export {PullToRefreshReducer};
