import {windowIsDefined} from 'app/helpers/windowIsDefined';
import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import * as Sentry from '@sentry/react';

const defaultSessionSampleRate = 0.00001; // 0.001% of sessions will be recorded

const sessionReplayInstance = Sentry.replayIntegration({
  errorSampleRate: 0,
  maskAllText: false,
  blockAllMedia: false,
});

const setContentGroupTag = () => {
  if (windowIsDefined) {
    const {template} = generateUniqueTemplateKey(window.location.pathname);
    const scope = Sentry.getCurrentScope();
    scope.setTag('content_group', template);
  }
};

const initSessionReplayIntegration = (sessionSampleRate = defaultSessionSampleRate) => {
  const client = Sentry.getClient();
  const hasReplayIntegration = client?.getIntegrationByName('Replay');
  if (client && !hasReplayIntegration) {
    const options = client.getOptions();
    options.replaysSessionSampleRate = sessionSampleRate;
    setContentGroupTag();
    client.addIntegration(sessionReplayInstance);
  }
};

export {initSessionReplayIntegration};
