import ExperimentalFeatureInterface from 'app/models/experimentalFeature/ExperimentalFeatureInterface';

export default class ExpFeatureNewDistrictPage implements ExperimentalFeatureInterface {
  getLabel(): string {
    return 'Kreisseite 2.0';
  }

  getSlug(): string {
    return 'experimental_feature_new_district_page';
  }
}
