export default class Path {
  pathname: string;
  root: string | undefined;
  slug: string | undefined;
  subroute: string | undefined;
  rest: (string | undefined)[];

  constructor(pathname: string) {
    this.pathname = pathname;
    const [root, slug, subroute, ...rest] = this.split();
    this.root = root;
    this.slug = slug;
    this.subroute = subroute;
    this.rest = rest;
  }
  split(): (string | undefined)[] {
    return this.pathname.split('/').filter(path => path);
  }

  isClub() {
    return this.root === 'club';
  }

  isCompetition() {
    return this.root === 'league' || this.root === 'cup';
  }
}
