import React from 'react';
import {AppBar} from 'app/components/appBar/appBar';
import styled from 'styled-components';
import {useLocation} from 'react-router';

const ResponsiveAppBar = styled(AppBar)`
  display: ${props => (props.mobileHideAppBar || props.alwaysHideAppBar ? 'none' : 'flex')};
  ${props => props.theme.desktopLayout`
    display: ${props => (props.alwaysHideAppBar ? 'none' : 'flex')};;
  `}
`;

/**
 * Conditionally renders AppBar based on route
 */
const AppBarWrapper = ({user}) => {
  const location = useLocation();
  const mobileHideAppBar =
    location.pathname === '/search' ||
    location.pathname.startsWith('/match/') ||
    location.pathname.startsWith('/news/') ||
    location.pathname.startsWith('/player/');
  const alwaysHideAppBar =
    location.pathname === '/start' || location.pathname === '/' || location.pathname.startsWith('/tv/');
  return <ResponsiveAppBar alwaysHideAppBar={alwaysHideAppBar} mobileHideAppBar={mobileHideAppBar} user={user} />;
};

export {AppBarWrapper};
