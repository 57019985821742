const detailPages = ['marketplace', 'topeleven'];
const extraPages = ['tv'];
const matchCalendarPages = ['live', 'cancelled'];

const generateUniqueTemplateKey = pathname => {
  const pathParams = pathname.split('/');
  pathParams.shift();
  let defaultSubType = 'index';
  if (pathParams?.[2] === 'season') {
    // Delete seasonal data e.g. /season/2019-20
    pathParams.splice(2, 2);
  } else if (
    matchCalendarPages.includes(pathParams?.[3]) &&
    `${pathParams?.[0]}_${pathParams?.[2]}` === 'region_matches'
  ) {
    // set unique template for matchCalendarPage filter `region_matches_live` or `region_matches_cancelled`
    defaultSubType = `${pathParams?.[2]}_${pathParams?.[3]}`;
  } else if (extraPages.includes(pathParams?.[0])) {
    // Set subtype for TvPlaylistPage `/tv/match/:slug`
    defaultSubType = 'detail';
  } else if (detailPages.includes(pathParams?.[2]) && pathParams?.[3]) {
    defaultSubType = `${pathParams?.[2]}_detail`;
  } else if (pathParams[0] === 'my') {
    if (pathParams[2]) {
      defaultSubType = pathParams[2];
    } else {
      defaultSubType = pathParams[1];
    }
  }
  const subType = pathParams?.[2] && defaultSubType === 'index' ? pathParams[2] : defaultSubType;
  // Set template for `/` to 'start_index'
  const type = pathParams?.[0] || 'start';
  return {
    template: `${type}_${subType}`,
    subType,
  };
};

export {generateUniqueTemplateKey};
