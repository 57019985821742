import * as Sentry from '@sentry/react';
import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import {windowIsDefined} from 'app/helpers/windowIsDefined';

const captureSentryException = (error, extra = {}, additionalTags) => {
  if (windowIsDefined) {
    const {template} = generateUniqueTemplateKey(window.location.pathname);
    const scope = Sentry.getCurrentScope();
    scope.setTag('content_group', template);
    if (additionalTags) {
      Object.keys(additionalTags).forEach(key => {
        scope.setTag(key, additionalTags[key]);
      });
    }
  }
  Sentry.captureException(error, extra);
};

export {captureSentryException};
