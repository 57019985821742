import React from 'react';
import {useSelector} from 'react-redux';
import {COLORS} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {createSelector} from 'reselect';
import {ArrowBack} from '@mui/icons-material';
import {windowIsDefined} from 'app/helpers/windowIsDefined';

const selectHeader = createSelector(
  state => state.header,
  header => header
);

const BackContainer = styled.div`
  padding: 0 0.75rem 0 1rem;
  min-width: 3.25rem;
  width: 1.5rem;
`;

const Back = ({color, className}) => {
  const header = useSelector(selectHeader);

  const back = () => {
    window.history.back();
  };

  let backIcon = (
    <BackContainer className={className} onClick={back} color={color}>
      <ArrowBack style={{color: color ?? COLORS.white}} />
    </BackContainer>
  );
  if (
    header.key.init === header.key.current ||
    (windowIsDefined && window.history.state && window.history.state.init === true)
  ) {
    backIcon = <BackContainer className={className} />;
  }

  return backIcon;
};

export {Back};
