import {captureSentryException} from 'app/services/sentryLogging';
import ExpFeatureNewDistrictPage from 'app/models/experimentalFeature/ExpFeatureNewDistrictPage';
import ExperimentalFeatureManager from 'app/models/experimentalFeature/ExperimentalFeatureManager';

export default class District {
  private readonly slug: string;

  constructor(slug: string) {
    if (!slug) {
      captureSentryException(new Error('District slug is missing while creating District model.'));
    }
    this.slug = slug;
  }

  getRootUrl(allowTest: boolean = true): string {
    return `/${this.getRootParam(allowTest && this.isTest())}/${this.slug}`;
  }

  getSubUrl(subUrl: string): string {
    const baseUrl = this.getRootUrl(false);
    const params = subUrl.startsWith('/') ? subUrl.substring(1) : subUrl;
    return `${baseUrl}/${params}`;
  }

  private getRootParam(isTest?: boolean): string {
    return isTest ? 'test_region' : 'region';
  }

  private isTest(): boolean {
    return ExperimentalFeatureManager.getInstance().isEnabled(new ExpFeatureNewDistrictPage()) ?? false;
  }
}
