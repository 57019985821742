import {authorizedAPI} from 'app/services/api';
import {getListOfFollows} from 'app/services/follow';
import {getDevices} from 'app/services/notifications';
import {captureSentryException} from 'app/services/sentryLogging';
import {catchDataError} from 'app/helpers/apiHelpers';
import {TimeoutMessage} from 'app/components/error/TimeoutMessage';
import {setElementToLocalStorage} from 'app/helpers/webStorageUtility';

// Actions for User at Firebase DB
function updateCurrentUser(data) {
  return {
    type: 'CURRENT_USER_UPDATE',
    data,
  };
}

function clearCurrentUser() {
  return {
    type: 'CURRENT_USER_CLEAR',
  };
}

// Actions for User at FuPa DB

function updateFuPaUser(data) {
  return {
    type: 'FUPA_USER_UPDATE',
    data,
  };
}

function fetchingFuPaUser(data) {
  return {
    type: 'FUPA_USER_FETCHING',
    data,
  };
}

function updateFollows(data) {
  return {
    type: 'FOLLOWS_UPDATE',
    data,
  };
}

function errorFollows(data) {
  return {
    type: 'FOLLOWS_ERROR',
    data,
  };
}

function askForEntityPush(data) {
  return {
    type: 'FOLLOW_ENTITY_ASK_FOR_PUSH',
    data,
  };
}

function askForEntityPushFinished() {
  return {
    type: 'FOLLOW_ENTITY_ASK_FOR_PUSH_FINISHED',
  };
}

function updatePushSupport(data) {
  return {
    type: 'PUSH_SUPPORT_UPDATE',
    data,
  };
}

function updateMessagingDeviceToken(data) {
  return {
    type: 'MESSAGING_DEVICE_TOKEN_UPDATE',
    data,
  };
}

function updateDevices(data) {
  return {
    type: 'UPDATE_DEVICES',
    data,
  };
}

function errorDevices(data) {
  return {
    type: 'ERROR_DEVICES',
    data,
  };
}

function setNotificationPermission(data) {
  return {
    type: 'UPDATE_NOTIFICATION_PERMISSION',
    data,
  };
}

function updateNotificationPermission(permission) {
  setElementToLocalStorage('notification-permission', permission);
  return setNotificationPermission(permission);
}

function setNotificationNoSupportBanner() {
  return {
    type: 'UPDATE_NOTIFICATION_NO_SUPPORT_BANNER',
  };
}

function updateNotificationNoSupportBanner() {
  setElementToLocalStorage('notification-no-support-banner', '1');
  return setNotificationNoSupportBanner();
}

function updatePPID(data) {
  return {
    type: 'UPDATE_PPID',
    data,
  };
}

const getFuPaUser = () => {
  return authorizedAPI.get('/users/current', {'axios-retry': {retries: 3, retryDelay: () => 150}});
};

function fetchFuPaUser() {
  return async function (dispatch) {
    dispatch(fetchingFuPaUser());
    try {
      const response = await getFuPaUser();
      dispatch(updateFuPaUser(response.data));
    } catch (error) {
      if (TimeoutMessage.test(error.message)) {
        // timeouts should not be caught for that
        return;
      }
      captureSentryException(error);
    }
  };
}

function fetchFollows() {
  return async function (dispatch) {
    try {
      const response = await getListOfFollows();
      dispatch(updateFollows(response.data));
    } catch (error) {
      const errorData = catchDataError(error);
      dispatch(errorFollows(errorData));
    }
  };
}

function fetchDevices() {
  return async function (dispatch) {
    try {
      const response = await getDevices();
      dispatch(updateDevices(response.data));
    } catch (error) {
      const errorData = catchDataError(error);
      dispatch(errorDevices(errorData));
    }
  };
}

export {
  updateCurrentUser,
  clearCurrentUser,
  fetchFuPaUser,
  fetchFollows,
  askForEntityPush,
  askForEntityPushFinished,
  updateMessagingDeviceToken,
  updatePushSupport,
  fetchDevices,
  updateNotificationPermission,
  updateNotificationNoSupportBanner,
  updatePPID,
};
