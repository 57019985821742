const initialData = {
  district: {slug: null, error: null},
  ssrRendered: process.env.ENVIRONMENT === 'local' ? true : typeof window === 'undefined',
  hyrationFinished: false,
  adsReady: false,
  consentStatus: 'loading',
  // only set via SSR
  previewMode: false,
  variant: 'default',
  adMonetizer: 'qm',
  authDialog: false,
};

const sessionReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'ADS_READY_UPDATE':
      return {...state, adsReady: action.data};
    case 'CONSENT_STATUS_UPDATE':
      return {...state, consentStatus: action.data};
    case 'SELECTED_DISTRICT_UPDATE':
      return {...state, district: action.data};
    case 'SELECTED_DISTRICT_ERROR':
      return {...state, district: {...state.district, error: action.data}};
    case 'SSR_UPDATE':
      return {...state, ssrRendered: action.data};
    case 'VARIANT_UPDATE':
      return {...state, variant: action.data};
    case 'HYDRATION_FINISHED':
      return {...state, hydrationFinished: true};
    case 'ADMONETIZER_UPDATE':
      return {...state, adMonetizer: action.data};
    case 'AUTH_DIALOG_UPDATE':
      return {...state, authDialog: action.data};
    default:
      return state;
  }
};

// will be used to pre-populate preloadedState in store.js
// We need to create a copy, otherwise initialData would act as global variable and would share session across different users
// Reason: redux-cookies-middleware.getStateFromCookies() does not copy data in preloadedState, it just extends it (which creates global variable/state)
const createInitialSessionData = (districtSlug, url) => {
  // Need to do deep copy of initial state to avoid sharing nested object values to different requests
  const copyInitialState = JSON.parse(JSON.stringify(initialData));
  let state = {...copyInitialState};

  // set flag for preview mode for SSR to define ad slots correctly if flag is used for them
  const [_, query] = url.split('?');
  if (query && query.includes('preview=true')) {
    state = {...state, previewMode: true};
  }

  if (districtSlug) {
    state = {...state, district: {slug: districtSlug}};
  }

  return state;
};

export {sessionReducer, createInitialSessionData};
