const initialData = {
  pathname: '',
  search: '',
};

const SsrLocationReducer = (state = initialData, action) => {
  return state;
};

const createSsrLocationData = pathnameWithExtras => {
  // Need to do deep copy of initial state to avoid sharing nested object values to different requests
  const copyInitialState = JSON.parse(JSON.stringify(initialData));
  let state = {...copyInitialState};

  const url = new URL(pathnameWithExtras, process.env.SSR_ORIGIN_URL);

  state = {...state, pathname: url.pathname, search: url.search};

  return state;
};

export {SsrLocationReducer, createSsrLocationData};
