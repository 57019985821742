import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {Content} from 'app/styles/content';
import {useSelector} from 'react-redux';
import {selectSsrRendered} from 'app/hooks/reduxCreateSelectorHooks';
import {Helmet} from 'react-helmet-async';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {windowIsDefined} from 'app/helpers/windowIsDefined';

const Wrapper = styled(Content)`
  &&& {
    padding: ${props => (props.top ? props.top : SIZES[72])} ${SIZES['16']};
    height: calc(100vh - ${props => (props.top ? props.top : SIZES[72])});
    margin: auto;
  }
`;

const SubTitle = styled(Typography)`
  margin: 1rem 0;
  display: block;
`;

const Text = styled(Typography)`
  &&& {
    color: ${COLORS.darkGrey};
  }
`;

const TextLink = styled(LinkOnComponent)`
  &&& {
    color: ${COLORS.fupaSecondary};
  }
`;

const NotFoundPage = ({id, top, text = '…wir konnten die angefragte Seite nicht finden.'}) => {
  const ssrRendered = useSelector(selectSsrRendered);

  let content = (
    <>
      <Typography variant='display1'>Hoppla...‍</Typography>
      <SubTitle variant='headline2'>{text} ⚽🤷🤷‍♂ </SubTitle>
      <Text variant='subheading'>
        Nutze die Suchfunktion oder gehe zur
        <TextLink to='/'> FuPa Startseite️</TextLink>.
      </Text>
    </>
  );

  // reloads page on client side only if location did not match any known route
  // let the server decide if location is unknown (server knows about redirects, client not)
  if (!ssrRendered && windowIsDefined) {
    content = (
      <>
        <Typography variant='display2'>Einen Moment...‍</Typography>
        <SubTitle variant='headline2'>...du wirst weitergeleitet. ⏳ </SubTitle>
      </>
    );

    if (process.env.ENVIRONMENT !== 'local') {
      window.location.reload();
    }
  }

  // Id used to check in renderer.js if component is rendered for SSR
  return (
    <Wrapper id={id} top={top}>
      <Helmet title={`Seite nicht gefunden${TITLE_POSTFIX}`} />
      {content}
    </Wrapper>
  );
};

export default NotFoundPage;
