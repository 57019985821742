import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import {Content} from 'app/styles/content';

const Wrapper = styled(Content)`
  height: 100vh;
  padding: 7.5rem ${SIZES['16']} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;

const SubTitle = styled(Typography)`
  margin: 1rem 0;
  display: block;
  padding-bottom: 1rem;
`;

const Text = styled(Typography)`
  &&& {
    text-align: center;
    color: ${COLORS.darkGrey};
  }
`;

const ServiceUnavailablePage = () => {
  let content = (
    <>
      <Typography variant='display1'>Sorry...</Typography>
      <SubTitle variant='headline2'>...wir befinden uns im Wartungsmodus. 🚧 </SubTitle>
      <Text variant='subheading'>
        Wegen geplanter Wartungsaufgaben ist der Inhalt vorübergehend nicht verfügbar. <p />
        Danke für dein Verständnis 💙 <p />
        Wir sind in wenigen Minuten wieder zurück!
      </Text>
    </>
  );

  return <Wrapper id={`http-error-503`}>{content}</Wrapper>;
};

export default ServiceUnavailablePage;
