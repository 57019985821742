import WebStorageManager, {StorageType} from 'app/services/web-storage/WebStorageManager';

export default class LocalStorageManager extends WebStorageManager {
  protected storageType: StorageType = StorageType.localStorage;
  private static instance: LocalStorageManager;

  private constructor() {
    super();
  }

  public static getInstance(): LocalStorageManager {
    if (!LocalStorageManager.instance) {
      LocalStorageManager.instance = new LocalStorageManager();
    }
    return LocalStorageManager.instance;
  }
}
