import StorageInterface from 'app/types/StorageInterface';
import WebStorageManager from 'app/services/web-storage/WebStorageManager';

export default class WebStorageAdapter implements StorageInterface {
  private storage: WebStorageManager;

  constructor(storage: WebStorageManager) {
    this.storage = storage;
  }

  get<T>(key: string): T | null {
    return this.storage.getItem(key);
  }

  set<T>(key: string, value: T, expiresInOrAt?: Date): void {
    this.storage.setItem(key, value, expiresInOrAt);
  }

  delete(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clearExpired();
  }
}
