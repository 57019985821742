import {createBrowserHistory} from 'history';
import {windowIsDefined} from 'app/helpers/windowIsDefined';

const createHistory = () => {
  let history = {};
  if (windowIsDefined) {
    history = createBrowserHistory();
    window.history.replaceState({init: true}, 'initState');
  }
  return history;
};

export default createHistory;
