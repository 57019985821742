import ExperimentalFeatureInterface from 'app/models/experimentalFeature/ExperimentalFeatureInterface';

export default class ExpFeatureWebInterstitial implements ExperimentalFeatureInterface {
  getLabel(): string {
    return 'Web Interstitial';
  }

  getSlug(): string {
    return 'experimental_feature_web_interstitial';
  }
}
