import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAdsReady} from 'app/components/ads/AdCore';
import {updateAdsReady} from 'app/actions/sessionActions';
import {selectAdMonetizer} from 'app/hooks/reduxCreateSelectorHooks';
import {captureSentryException} from 'app/services/sentryLogging';

const scriptSrc = {
  qm: '/ads/qmax_min.js?v33',
};

const initAdScript = adMonetizer => {
  const script = document.createElement('script');
  script.src = scriptSrc[adMonetizer];
  script.async = true;
  script.type = 'text/javascript';
  document.head.appendChild(script);
};

const useInitAdSetup = () => {
  // Skip for SSR
  if (typeof window === 'undefined') {
    return;
  }

  const adMonetizer = useSelector(selectAdMonetizer);
  const adsReady = useSelector(selectAdsReady);
  const dispatch = useDispatch();
  const isQM = adMonetizer === 'qm';

  useEffect(() => {
    // Only for QM Setup
    // Need to listen for namespace `window.qm_adserver_ready` to set flag `adsReady` to trigger Ads
    if (!adsReady && isQM) {
      const checkForQMReady = setInterval(() => {
        if (window.qm_adserver_ready) {
          clearInterval(checkForQMReady);
          dispatch(updateAdsReady(true));
        }
      }, 50);
    }
  }, [adsReady]);

  useEffect(() => {
    window.addEventListener('tcfError', function (event) {
      const additionalTag = {event_status: event.detail?.eventStatus};
      const hasValidTCData = event.detail?.eventStatus === 'cmpuishown';
      if (!hasValidTCData) {
        captureSentryException(
          new Error('tcfError: TCF not loaded, invalid tcData'),
          {extra: event.detail},
          additionalTag
        );
      }
    });
  }, []);

  useEffect(() => {
    if (adMonetizer) {
      initAdScript(adMonetizer);
    }
  }, [adMonetizer]);
};

export {useInitAdSetup};
