import {useIsDesktop} from 'app/components/ads/useIsDesktop';
import {useEffect, useState} from 'react';

const getAdDimensions = (isDesktop, limitedDim) => {
  // Prebid: Make sure the creative size is set to 1x1. This allows us to set up size override, which allows this creative to serve on all inventory sizes.
  const content1Dimensions = isDesktop
    ? [
        [970, 250],
        [640, 360],
        [640, 480],
        [800, 250],
        [300, 250],
        [336, 280],
        [320, 50],
        [320, 150],
        [250, 250],
        [1, 1],
        'fluid',
      ]
    : [[300, 250], [320, 100], [336, 280], [320, 50], [320, 150], [250, 250], [1, 1], 'fluid'];
  const native1Dimensions = isDesktop
    ? [[336, 280], [300, 250], [320, 50], [300, 300], [250, 250], 'fluid']
    : [[336, 280], [300, 250], [320, 50], [320, 75], [320, 100], [320, 150], [300, 300], [250, 250], 'fluid'];
  const native2Dimensions = [[320, 50], [320, 75], [320, 100], [1, 1], 'fluid'];
  const rectangle1Dimensions = isDesktop
    ? [[300, 250], [250, 250], [300, 600], [336, 280], [320, 50], [320, 150], [640, 480], 'fluid']
    : [
        [300, 250],
        [250, 250],
        [320, 50],
        [320, 75],
        [320, 100],
        [320, 150],
        [336, 280],
        [300, 600],
        [320, 480],
        [1, 1],
        'fluid',
      ];
  const floatingContentDimensions = [[300, 250]];
  const floatingRectangleDimensions = [[300, 250]];
  const content2Dimensions = isDesktop
    ? [[300, 600], [336, 280], [300, 250], [320, 50], [300, 300], [250, 250], [640, 360], [640, 480], 'fluid']
    : [[300, 600], [336, 280], [300, 250], [300, 300], [250, 250], [320, 480], 'fluid'];
  const rectangle2Dimensions = isDesktop
    ? [[300, 600], [300, 250], [300, 300], [336, 280], [640, 480], 'fluid']
    : [
        [300, 600],
        [300, 250],
        [300, 300],
        [320, 50],
        [320, 75],
        [320, 100],
        [320, 150],
        [320, 480],
        [336, 280],
        'fluid',
      ];
  const superbannerDimensions = isDesktop
    ? [
        [728, 90],
        [970, 90],
        [800, 250],
        [970, 250],
      ]
    : null;
  const sodSuperbannerDimensions = isDesktop ? [...superbannerDimensions, [1, 1]] : null;
  const skyscraperDimensions = isDesktop
    ? [
        [120, 600],
        [160, 600],
        [300, 250],
        [300, 600],
        [1, 1],
      ]
    : null;

  const stickyMobileDefaultDimensions = [
    [320, 50],
    [1, 1],
  ];
  const stickyMobileBannerDimensions = isDesktop ? null : stickyMobileDefaultDimensions;
  const edWDimension = [[1, 1], 'fluid'];
  const matchEvent1Dimensions = [[320, 50], [320, 75], [320, 100], [320, 150], 'fluid'];
  const content1Dim = limitedDim && isDesktop ? floatingContentDimensions : content1Dimensions;
  return {
    Content_1: content1Dim,
    Native_1: native1Dimensions,
    Native_2: native2Dimensions,
    Rectangle_1: limitedDim && isDesktop ? floatingRectangleDimensions : rectangle1Dimensions,
    Content_2: content2Dimensions,
    Rectangle_2: limitedDim && isDesktop ? floatingRectangleDimensions : rectangle2Dimensions,
    Superbanner: superbannerDimensions,
    Superbanner_2: sodSuperbannerDimensions,
    Superbanner_3: sodSuperbannerDimensions,
    Skyscraper: skyscraperDimensions,
    Skyscraper_2: skyscraperDimensions,
    Sticky_Mobile_Banner: stickyMobileBannerDimensions,
    Mobile_Banner: stickyMobileBannerDimensions,
    EdW_Banner_Top: edWDimension,
    EdW_Banner_Bottom: edWDimension,
    EdW_Banner_Center: edWDimension,
    MatchEvent_1: matchEvent1Dimensions,
  };
};

const useAdDimensions = limitedDim => {
  const isDesktop = useIsDesktop();
  const [dimensions, setDimensions] = useState(getAdDimensions(isDesktop, limitedDim));

  useEffect(() => {
    setDimensions(getAdDimensions(isDesktop, limitedDim));
  }, [isDesktop]);

  return dimensions;
};

export {useAdDimensions, getAdDimensions};
