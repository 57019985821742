import axios from 'axios';
import {captureSentryException} from 'app/services/sentryLogging';
import {getElementFromLocalStorage, setElementToLocalStorage} from 'app/helpers/webStorageUtility';

const FIVE_MINUTES_IN_MS = 300000;

const getAppRevision = async () => {
  try {
    const response = await axios.get(`${process.env.SSR_ORIGIN_URL}/app-revision`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const getAppRevisionFromLocalStorage = () => {
  const appRevision = getElementFromLocalStorage('appRevision');
  return appRevision ? JSON.parse(appRevision) : null;
};

const setAppRevisionToLocalStorage = (revision, timestamp) => {
  setElementToLocalStorage('appRevision', JSON.stringify({revision, timestamp}));
};

export const checkCurrentAppRevision = async () => {
  const currentAppRevision = await getAppRevision();
  if (!currentAppRevision) {
    return;
  }
  const ssrAppRevision = document.getElementById('app-revision')?.textContent;
  if (!ssrAppRevision) {
    captureSentryException(new Error('No app revision found in SSR'), {extra: {currentAppRevision}});
  }
  if (ssrAppRevision !== currentAppRevision) {
    const lastAppRevision = getAppRevisionFromLocalStorage();
    const now = new Date().getTime();
    if (lastAppRevision) {
      const {timestamp} = lastAppRevision;
      if (now - timestamp < FIVE_MINUTES_IN_MS) {
        return;
      }
    }
    setAppRevisionToLocalStorage(currentAppRevision, now);
    window.location.reload();
  }
};
