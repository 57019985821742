import {createSelector} from 'reselect';

interface District {
  id: number;
  name: string;
  middleName: string;
  shortName: string;
  slug: string;
  region: {
    id: number;
    name: string;
    slug: string;
    linkUrl: string;
  };
}

interface RootState {
  session: {
    district: District;
  };
}

export const reduxSelectDistrict = createSelector([(state: RootState) => state.session.district], district => district);
