import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import React from 'react';
import styled from 'styled-components';
import {Content} from 'app/styles/content';
import {LinkOnComponent} from 'app/components/links/link';

const Wrapper = styled(Content)`
  padding: ${SIZES['32']} ${SIZES['16']};
  height: 80vh;
`;

const SubTitle = styled(Typography)`
  margin: 1rem 0;
  display: block;
`;

const Text = styled(Typography)`
  &&& {
    display: inline-flex;
    color: ${COLORS.darkGrey};
  }
`;

const TextLink = styled(LinkOnComponent)`
  &&& {
    color: ${COLORS.fupaSecondary};
  }
`;

const ComponentErrorHandler = () => {
  let content = (
    <>
      <Typography variant='display1'>Sorry...‍</Typography>
      <SubTitle variant='headline2'>...hier ist etwas schief gelaufen. 🐛</SubTitle>
      <Typography variant='subheading2'>
        Wir wurden bereits benachrichtigt und werden uns direkt darum kümmern.
      </Typography>
      <Text variant='subheading'>
        Zurück zur
        <TextLink as='a' data-google-interstitial='false' href='/'>
          &nbsp;FuPa Startseite️
        </TextLink>
      </Text>
    </>
  );

  return <Wrapper>{content}</Wrapper>;
};

export {ComponentErrorHandler};
