import axios from 'axios';
import {CLIENT_API_RESPONSE_TIMEOUT} from 'app/services/api';

const baseURL = process.env.FOLLOW_URL;

const followApi = axios.create({baseURL, timeout: CLIENT_API_RESPONSE_TIMEOUT});

followApi.interceptors.request.use(
  async config => {
    const {tokenValidationForXHR} = await import('app/services/firebase');
    const token = await tokenValidationForXHR();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const getListOfFollows = () => {
  return followApi.get('/follows');
};

const updateFollowEntity = (entityType, entityId, isFollowed, push = false) => {
  const request = `/${entityType}/${entityId}`;
  if (isFollowed) {
    return followApi.delete(request);
  } else {
    return followApi.post(request, {push});
  }
};

const updatePushEntity = (entityType, entityId, push = true) => {
  return followApi.post(`/${entityType}/${entityId}`, {push});
};

const updateAllPushes = pushOption => {
  return followApi.post('/follows', {push: pushOption});
};

export {getListOfFollows, updateFollowEntity, updatePushEntity, updateAllPushes};
