import {css} from 'styled-components';

// used directly within SC (no import needed)
const desktopContentWidth = 970; // Threshold for Layout-Breakpoint
const mobileContentWidth = 640;
const elementsWidth2Columns = 475;

const adMaxSkyscraperWidth = 300;
const defaultSpace = 16;
const fabWidth = 72;
const maxWidthFabPlaced = desktopContentWidth + fabWidth;

const contentWidthWithHalfSky = defaultSpace * 2 + desktopContentWidth + adMaxSkyscraperWidth;

// Used to adjust components to max-width of content
const desktopLayout = (...args) => css`
  @media only screen and (min-width: ${desktopContentWidth}px) {
    ${css(...args)};
  }
`;

const streamLayoutOneColBig = (...args) => css`
  @media only screen and (min-width: ${mobileContentWidth}px) and (max-width: ${desktopContentWidth}px) {
    ${css(...args)};
  }
`;

const desktopLayoutWithHalfSky = (...args) => css`
  // Skyscraper needs min. 50% viewbility to be placed, needed min-with: 1302px
  @media only screen and (min-width: ${contentWidthWithHalfSky}px) {
    ${css(...args)};
  }
`;

const desktopLayoutWithFab = (...args) => css`
  @media only screen and (min-width: ${maxWidthFabPlaced}px) {
    ${css(...args)};
  }
`;

// used for tv video player
const isPortrait = (...args) => css`
  @media screen and (orientation: portrait) {
    ${css(...args)};
  }
`;

const checkDesktopLayout = adUnit => {
  const isSky = adUnit?.includes('Skyscraper') ?? false;
  return isSky
    ? window.matchMedia(`(min-width: ${contentWidthWithHalfSky}px)`).matches
    : window.matchMedia(`(min-width: ${desktopContentWidth}px)`).matches;
};

const streamModuleBreakpointSmall = 360;
const streamModuleBreakpointMedium = 390;
const streamModuleBreakpointLarge = 680;
const streamModuleViewsBreakpoint = 520;

const streamModuleSmall = (...args) => css`
  @media only screen and (min-width: ${streamModuleBreakpointSmall}px) {
    ${css(...args)};
  }
`;

const streamModuleMedium = (...args) => css`
  @media only screen and (min-width: ${streamModuleBreakpointMedium}px) {
    ${css(...args)};
  }
`;

const streamModuleLarge = (...args) => css`
  @media only screen and (min-width: ${streamModuleBreakpointLarge}px) {
    ${css(...args)};
  }
`;

const streamModuleFourElemsPerViews = (...args) => css`
  @media only screen and (min-width: ${streamModuleViewsBreakpoint}px) {
    ${css(...args)};
  }
`;

export {
  desktopContentWidth,
  contentWidthWithHalfSky,
  defaultSpace,
  mobileContentWidth,
  elementsWidth2Columns,
  fabWidth,
  desktopLayout,
  desktopLayoutWithHalfSky,
  desktopLayoutWithFab,
  streamLayoutOneColBig,
  isPortrait,
  adMaxSkyscraperWidth,
  checkDesktopLayout,
  streamModuleBreakpointSmall,
  streamModuleSmall,
  streamModuleBreakpointMedium,
  streamModuleMedium,
  streamModuleBreakpointLarge,
  streamModuleLarge,
  streamModuleFourElemsPerViews,
};
