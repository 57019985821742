import {windowIsDefined} from 'app/helpers/windowIsDefined';

const isNativeApp = () => {
  if (windowIsDefined) {
    // Legacy Native: iOS (Deprecated)
    if (window.webkit?.messageHandlers.native) {
      return !!window.webkit.messageHandlers.native;
    }
    if (window.native) {
      // Legacy Native: Android postMessage channel (Deprecated)
      // Flutter Core WebView: android & ios postMessage channel available on window.native
      return !!window.native;
    }
  }

  return false;
};

const isNativeFlutterAndroidApp = () => {
  // JS channel is only supported in new native Flutter app
  return windowIsDefined && window.native_android;
};

const isNativeFlutterIOSApp = () => {
  // JS channel is only supported in new native Flutter app
  return typeof windowIsDefined && window.native_ios;
};

const isNativeFlutterApp = () => {
  return isNativeFlutterAndroidApp() || isNativeFlutterIOSApp();
};

export {isNativeFlutterApp, isNativeApp};
