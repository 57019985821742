// Set bool to detect when observer is called for the first time so firebase initializing completed
// and access to user state can be verified

const initialData = {
  initializing: true,
  user: {},
  claims: {},
  fuPaUser: {},
  fuPaUserFetching: false,
  follows: [],
  followsFetched: false,
  followsError: null,
  newFollow: {
    askForPush: false,
    entity: null,
  },
  pushSupport: false,
  notificationPermission: '',
  notificationNoSupportBanner: 0,
  messagingDeviceToken: '',
  devices: [],
  devicesFetched: false,
  devicesError: null,
  ppid: '',
};

const CurrentUserReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'CURRENT_USER_UPDATE':
      return {...state, user: action.data.user, claims: action.data.claims, initializing: false};
    case 'CURRENT_USER_CLEAR':
      return {...initialData, initializing: false, ppid: state.ppid};
    case 'FUPA_USER_FETCHING':
      return {...state, fuPaUserFetching: true};
    case 'FUPA_USER_UPDATE':
      return {...state, fuPaUser: action.data, fuPaUserFetching: false};
    case 'FOLLOWS_UPDATE':
      return {...state, follows: action.data, followsFetched: true, followsError: null};
    case 'FOLLOWS_ERROR':
      return {...state, follows: [], followsFetched: true, followsError: action.data};
    case 'FOLLOW_ENTITY_ASK_FOR_PUSH':
      return {...state, newFollow: {askForPush: true, entity: action.data}};
    case 'FOLLOW_ENTITY_ASK_FOR_PUSH_FINISHED':
      return {...state, newFollow: {...initialData.newFollow}};
    case 'PUSH_SUPPORT_UPDATE':
      return {...state, pushSupport: action.data};
    case 'MESSAGING_DEVICE_TOKEN_UPDATE':
      return {...state, messagingDeviceToken: action.data};
    case 'UPDATE_DEVICES':
      return {...state, devices: action.data, devicesFetched: true, devicesError: null};
    case 'ERROR_DEVICES':
      return {...state, devices: [], devicesFetched: true, devicesError: action.data};
    case 'UPDATE_NOTIFICATION_PERMISSION':
      const allowedValues = ['default', 'granted', 'denied', 'deactivated', 'unsupported'];
      const notificationPermission = allowedValues.includes(action.data) ? action.data : state.notificationPermission;
      return {...state, notificationPermission};
    case 'UPDATE_NOTIFICATION_NO_SUPPORT_BANNER':
      return {...state, notificationNoSupportBanner: 1};
    case 'UPDATE_PPID':
      return {...state, ppid: action.data};
    default:
      return state;
  }
};

// will be used to pre-populate preloadedState in store.js
// We need to create a copy, otherwise initialData would act as global variable and would share data across different users
// Reason: redux-cookies-middleware.getStateFromCookies() does not copy data in preloadedState, it just extends it (which creates global variable/state)
const createInitialUserData = () => {
  // Need to do deep copy of initial state to avoid sharing nested object values to different requests
  const copyInitialState = JSON.parse(JSON.stringify(initialData));
  return {...copyInitialState};
};

export {CurrentUserReducer, createInitialUserData};
