import styled from 'styled-components';
import {SIZES} from '@fupa/fupa-uikit';

const Svg = styled.svg`
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: ${SIZES[24]};
  flex-shrink: 0;
`;

export {Svg};
