import {useCallback, useEffect, useRef} from 'react';
import {captureSentryException} from 'app/services/sentryLogging';

const useTokenExpirationCheck = (user, claims) => {
  const checkExpiration = useRef(null);

  const getCheckExpirationOfToken = useCallback(async () => {
    if (checkExpiration.current) {
      return checkExpiration.current;
    }
    try {
      const {checkExpirationOfToken} = await import('app/services/firebase');
      return (checkExpiration.current = checkExpirationOfToken);
    } catch (error) {
      captureSentryException(error, {extra: {errorLocation: 'importing checkExpirationOfToken from firebase'}});
      return null;
    }
  }, []);

  useEffect(() => {
    let intervalId;
    if (Object.keys(user).length === 0) {
      return;
    }
    const handleExpirationCheck = async () => {
      const checkExpiration = await getCheckExpirationOfToken();
      if (!checkExpiration) {
        return;
      }
      checkExpiration(user, claims.exp).catch(error => {
        if (error.code === 'auth/user-disabled') {
          history.push('/');
        }
        clearInterval(intervalId);
      });
    };
    // Check token expiration date every 60s
    intervalId = setInterval(handleExpirationCheck, 60000);
    // Executes handler on focus change, e.g. window paused / in background and comes to foreground again
    window.addEventListener('focus', handleExpirationCheck);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('focus', handleExpirationCheck);
    };
  }, [user, claims]);
};

export default useTokenExpirationCheck;
