import {generateUniqueTemplateKey} from 'app/helpers/gtm';

const sendEventToGA4 = (eventName, eventParams) => {
  if (process.env.ENVIRONMENT !== 'local') {
    const {template} = generateUniqueTemplateKey(document.location.pathname);
    // Check if param 'content_group' already exists
    // For 'page_view' it is necessary that 'content_group' is already set, because the events are not immediately sent
    // For 'search' it is necessary that 'content_group' is already set, because the event is sent on unmounting the search page
    // For 'page_view' and 'search' document.location.pathname could have changed already
    const params = eventParams.content_group ? eventParams : {...eventParams, content_group: template};

    if (eventName === 'page_view') {
      gtag('get', process.env.GTAG_ID, 'user_properties', up => {
        gtag('event', eventName, {...params, fupa_region_property: up?.fupa_region});
      });
    } else {
      console.log('gtag event', eventName, params);
      gtag('event', eventName, params);
    }
  }
};

const setDimensionToGA4 = paramValuePair => {
  if (process.env.ENVIRONMENT !== 'local') {
    gtag('set', paramValuePair);
  }
};

export {sendEventToGA4, setDimensionToGA4};
