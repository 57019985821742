function changeHeaderStyle(value) {
  return {
    type: 'HEADER_STYLE_CHANGE',
    value,
  };
}

function changeHeaderFollowing(value) {
  return {
    type: 'HEADER_FOLLOWING',
    value,
  };
}

function changeHeaderText(value) {
  return {
    type: 'HEADER_TEXT_CHANGE',
    value,
  };
}

function changeHeaderEntity(entity) {
  return {
    type: 'HEADER_ENTITY_CHANGE',
    entity,
  };
}

function toggleHeader(value) {
  return {
    type: 'HEADER_TOGGLE',
    value,
  };
}

function setHeaderAutoScroll(value) {
  return {
    type: 'HEADER_AUTO_SCROLL',
    value,
  };
}

function resetHeader(data) {
  return {
    type: 'HEADER_RESET',
    data,
  };
}

function updateHeaderKey(data) {
  return {
    type: 'HEADER_KEY_UPDATE',
    data,
  };
}

function updateHeaderPreviousPathname(data) {
  return {
    type: 'HEADER_PREVIOUS_PATHNAME_UPDATE',
    data,
  };
}

export {
  changeHeaderStyle,
  changeHeaderFollowing,
  changeHeaderText,
  changeHeaderEntity,
  toggleHeader,
  setHeaderAutoScroll,
  resetHeader,
  updateHeaderKey,
  updateHeaderPreviousPathname,
};
