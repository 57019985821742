import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import {Content} from 'app/styles/content';

const Wrapper = styled(Content)`
  height: 100vh;
  padding: 7.5rem ${SIZES['16']} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;

const SubTitle = styled(Typography)`
  margin: 1rem 0;
  display: block;
  padding-bottom: 1rem;
`;

const Text = styled(Typography)`
  &&& {
    text-align: center;
    color: ${COLORS.darkGrey};
  }
`;

const GenericServerErrorPage = ({status}) => {
  let content = (
    <>
      <Typography variant='display1'>Sorry...</Typography>
      <SubTitle variant='headline2'>...diese Seite ist aktuell nicht erreichbar.</SubTitle>
      <Text variant='subheading'>
        Wegen aktueller Server-Probleme ist der Inhalt vorübergehend nicht verfügbar. <p />
        Bitte versuche es in wenigen Minuten noch einmal. <p />
        Danke für dein Verständnis 💙
      </Text>
    </>
  );

  return <Wrapper id={`http-error-${status}`}>{content}</Wrapper>;
};

export default GenericServerErrorPage;
