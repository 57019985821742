const ignoreErrorMessage = [
  /NS_ERROR_NOT_INITIALIZED/,
  /Illegal invocation/,
  /null has no properties/,
  /i is null/,
  /ResizeObserver loop limit exceeded/,
  /\.require is not a function/,
  /^Request aborted$/,
  /SecurityError: Blocked a frame with origin/,
  /^Failed to fetch$/,
  /^Cannot read property 'document' of null$/,
  /^Network Error$/,
  /^Not a window$/,
  /timeout of 0ms exceeded/,
  /AMP_TOP/,
  /Cannot read property 'disguiseToken' of undefined/,
  /Failed to read the 'buffered' property from 'SourceBuffer'/,
  /InvalidStateError: An attempt was made to use an object that is not, or is no longer, usable/,
  /InvalidStateError: The object is in an invalid state/,
  /SecurityError: Blocked a restricted frame with origin "https:\/\/www\.fupa\.net" from accessing another frame/,
  /NetworkError: Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https:\/\/t\.richaudience/,
  /Non-Error promise rejection captured with keys: _errorCode, _errorMessage/,
  /Non-Error promise rejection captured with keys: context, currentTarget, isTrusted/,
  /Event `Event` \(type=error\) captured as promise rejection/,
];

export {ignoreErrorMessage};
