import styled from 'styled-components';

const AppBarDesktopWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${props => props.theme.desktopContentWidth}px;
  margin: auto;
`;

export {AppBarDesktopWrapper};
