let handlers = [];

const checkAdPosition = (slotId, lazyLoadThresholdScrollDown, loadSlot) => {
  const lazyAdSlot = document.getElementById(slotId);
  const notLoaded = lazyAdSlot?.dataset['lazyLoad'];
  if (notLoaded) {
    const currentAdPositionBottom = lazyAdSlot?.getBoundingClientRect().bottom;
    const currentAdPositionTop = lazyAdSlot?.getBoundingClientRect().top;

    // Load slot as soon as bottom of ad is in viewport
    // or as soon as top of viewport is in viewport inclusively threshold under the fold (lazyLoadThresholdScrollDown)
    const hasReachedThreshold = currentAdPositionTop <= lazyLoadThresholdScrollDown;
    const isInViewportWithTopPosition = currentAdPositionTop > 0 && hasReachedThreshold;
    const isInViewportWithBottomPosition = currentAdPositionBottom > 0 && currentAdPositionBottom <= window.innerHeight;

    if (isInViewportWithTopPosition || isInViewportWithBottomPosition) {
      loadSlot();
      lazyAdSlot.removeAttribute('data-lazy-load');
    }
  }
};

const lazyLoad = (slotId, loadSlot, isDesktop) => {
  const NAVBAR_HEIGHT = isDesktop ? 0 : 64;
  const viewportFactor = isDesktop ? 1 : 1.1;
  const lazyLoadThresholdScrollDown = window.innerHeight * viewportFactor;
  const lazyAdSlot = document.getElementById(slotId);
  let adPositionTop, adPositionBottom;
  if (lazyAdSlot) {
    ({top: adPositionTop, bottom: adPositionBottom} = lazyAdSlot.getBoundingClientRect());
    // initVisibleTopPositionCondition is relevant for landing page and forward navigation
    // initVisibleBottomPositionCondition is relevant for backward navigation
    const initVisibleTopPositionCondition =
      adPositionTop && adPositionTop > 0 && adPositionTop <= window.innerHeight - NAVBAR_HEIGHT;
    const initVisibleBottomPositionCondition =
      adPositionBottom && adPositionBottom > 0 && adPositionBottom <= window.innerHeight;

    if (
      initVisibleTopPositionCondition ||
      initVisibleBottomPositionCondition ||
      slotId === 'ad-Skyscraper' ||
      slotId === 'ad-Skyscraper_2'
    ) {
      // 1. Load all slots visible in viewport initially for landing page, forward navigation and backward navigation
      loadSlot();
    } else {
      lazyAdSlot.setAttribute('data-lazy-load', 'true');
      // 2. Load slots on scroll which are not visible yet but placed in the second viewport
      // 3. Load slots on scroll as soon as the come into the second viewport
      // 4. Load slots on back navigation with restored scrollY
      const handlerFunc = function () {
        checkAdPosition(slotId, lazyLoadThresholdScrollDown, loadSlot);
      };

      handlers.push(handlerFunc);
      window.addEventListener('scroll', handlerFunc);
    }
  }
};

const destroyLazyLoadListener = () => {
  handlers.forEach(handler => {
    window.removeEventListener('scroll', handler);
  });
};

export {lazyLoad, destroyLazyLoadListener};
