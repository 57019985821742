const partnerInitData = {
  brandings: [],
  ivw: {},
};

const partnerReducer = (state = partnerInitData, action) => {
  switch (action.type) {
    case 'PARTNER_CLEAR':
      return {...partnerInitData};
    case 'PARTNER_UPDATE':
      return action.data;
    default:
      return state;
  }
};

export {partnerInitData, partnerReducer};
