const generateTrackingService = () => {
  let lastPagePathSent;
  const eventQueue = [];

  return function (data, page, sendEvent) {
    // Abstract trigger to send events to tracking tools like ivw and gtm
    // Example: Pageview -Use Cases to send pageview for
    // 1. route change /pageA -> /pageB
    //  1.1 /pageA already sent, start timer for /pageB
    //  1.2 /pageA not sent yet, send /pageA and start timer for /pageB
    // 2. updates on route /a
    //  2.1 /pageA updates its title and no event was sent for /pageA yet, restart timer for /pageA
    //  2.2 /pageA updates its title but event was already sent for /pageA, skip following events for /pageA
    // 3. initial pageview with no route change

    if (typeof window === 'undefined') {
      return;
    }
    // Queue eventQueue to prevent sending duplicate or incomplete pageview events
    eventQueue.push({data, page});

    if (eventQueue.length === 2) {
      if (eventQueue[0].page !== eventQueue[1].page) {
        // 1.2
        lastPagePathSent = eventQueue[0].page;
        sendEvent(eventQueue[0].data);
      }
      // Remove first element of array because it is duplicated or already sent
      eventQueue.splice(0, 1);
    } else if (eventQueue.length) {
      //  Send event after timeout of 2s
      // 2.1
      // 3.
      if (!lastPagePathSent || eventQueue[0].page !== lastPagePathSent) {
        setTimeout(() => {
          sendEvent(eventQueue[0].data);
          lastPagePathSent = eventQueue[0].page;
          eventQueue.splice(0, 1);
        }, 2000);
      } else if (eventQueue[0].page === lastPagePathSent) {
        // 2.2
        eventQueue.splice(0, 1);
      }
    }
  };
};

export {generateTrackingService};
