import React from 'react';
import {COLORS} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {createSelector} from 'reselect';
import {NAVBAR_HOME} from 'app/reducers/navbar';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {FuPaPlayer} from 'app/components/icons';
import {useHistory} from 'react-router';

const Wrapper = styled.div`
  display: none;
  ${props => props.theme.desktopLayout`
    display: flex;
    margin-right: 1rem;
    margin-left: 1.5rem;
    cursor: pointer;
  `}
`;

const StyledFuPaPlayer = styled(FuPaPlayer)`
  path:last-child {
    fill: ${COLORS.white};
  }
`;

const selectNavBar = createSelector(
  state => state.navBar,
  navBar => {
    return {
      defaultUrl: navBar.defaultUrl,
    };
  }
);

const DesktopLogo = () => {
  const {defaultUrl} = useSelector(selectNavBar);
  const history = useHistory();
  const isRegion = /^\/region\//.test(history.location.pathname);
  const fupaPlayer = <StyledFuPaPlayer style={{color: COLORS.fupaSecondary, fontSize: 36}} />;
  const handleClick = () => {
    if (!isRegion) {
      history.push(defaultUrl[NAVBAR_HOME]);
    }
  };

  return (
    <Wrapper onClick={handleClick}>
      {isRegion ? <LinkOnComponent to={defaultUrl[NAVBAR_HOME]}>{fupaPlayer}</LinkOnComponent> : fupaPlayer}
    </Wrapper>
  );
};

export {DesktopLogo};
